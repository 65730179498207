@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.wrapper {
  display: flex;
  flex-direction: column;

  .itemContainer {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .titleHeader {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
    color: white;
  }

  .titleItem {
    font-size: 14px;
    font-weight: 500;
    color: white;
    line-height: 19.6px;
  }

  .contentItem {
    font-size: 12px;
    font-weight: '400';
    color: white;
    opacity: 0.7;
    line-height: 20px;
  }

  .label {
    font-size: 12px;
    font-weight: '100';
    color: white;
    opacity: 0.7;
  }

  .rewardToken {
    font-size: 14px;
    font-weight: 600;
  }

  .boostInfo {
    font-size: 12px;
    font-weight: '400';
    color: white;
    opacity: 0.7;
    line-height: 20px;
  }
}
