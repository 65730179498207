@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.bottomBarContainer {
  width: 100%;
  min-height: 72px;
  height: 72px;
  background: var(--alpha-navBg);
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px;
  gap: 20px;
  border-top: 1px solid var(--alpha-chakraBox);
  .btnAction {
    height: 40px !important;
    width: 100%;
    padding: 4px 20px !important;
  }
  .btnBuy {
    &.isDisabled {
      background-color: #f8f0ac;
      opacity: 1;
    }

    &.isNotAllowBuy {
      background-color: #f8f0ac;
      padding-top: 18px;
      padding-bottom: 18px;
    }

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1%;

      /* Color-newsystem/White/100 */
      color: var(--alpha-btn-text) !important;
    }
    .price {
      font-size: 10px;
      line-height: 16px;
      opacity: 0.7;
    }
  }
}

.errorText {
  font-size: rem(11);
  margin-top: 1px;
  color: #ff6868 !important;
  font-weight: 500;
  align-self: flex-start;
  margin-bottom: 5px;
}

.buyer {
  color: var(--alpha-text) !important;
  line-height: 20px;
  max-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // line-height: 100%;
  font-weight: 500;
  font-size: 14px;
}
.desc {
  font-size: 12px;
  opacity: 0.7;
}

.itemSetting {
  flex: 1;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  .title {
    color: white;
  }
  .itemAmounts {
    .itemAmount {
      flex: 1;
      align-items: center;
      justify-content: center;
      padding: 4px 8px;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      &:last-child {
        border-right: 0;
      }
      &.itemAmountActive {
        background-color: var(--chakra-colors-blue-600);
        p {
          color: #000 !important;
        }
      }
    }
  }
  :global {
    .chakra-popover__popper {
      .chakra-popover__content {
        border: none !important;
        border-radius: 4px;
      }
      .chakra-popover__body {
        border-radius: 4px;
        padding: 0;
        border: 1px solid rgba(255, 255, 255, 0.2);
        overflow: hidden;
      }
    }
  }
}

.btnChangeAmount {
  padding: 6px 20px !important;
  border: 1px solid #353945 !important;
  border-radius: 4px !important;
  background: linear-gradient(0deg, #1b1e26, #1b1e26),
    linear-gradient(0deg, #353945, #353945) !important;
  color: white !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
  height: 32px;
  width: 80px;
}

.modalItem {
  width: 100%;
}

.itemModalContainer {
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
