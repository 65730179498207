@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  :global {
    .faqs-wrapper {
      //padding: rem(16);
      padding-top: 0;
      .chakra-accordion__item {
        padding-bottom: 24px;
        padding-top: 24px;
      }
      .chakra-accordion__button {
        padding-bottom: 0;
        outline: 0;
        background: transparent;
      }
      .faq-question {
        /* This will enable people to learn more about you and further establish trust? */

        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* or 21px */

        color: var(--alpha-text);
      }
      .chakra-collapse {
        padding-top: 12px;
        .chakra-accordion__panel {
          padding-bottom: 0;
          .faq-answer {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            /* or 21px */

            color: var(--alpha-text);

            p {
              color: inherit;
            }

            ol {
              list-style: decimal;
            }
          }
        }
      }
    }
  }
}

.btnMenuBar {
  cursor: pointer;
  font-size: 14px;
}

.modalContent {
  :global {
    padding: 20px !important;
  }
}
