@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.wrapper {
  display: flex;
  flex-direction: column;

  p {
    color: white;
  }

  span {
    font-weight: bold;
  }

  .titleHeader {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 12px;
  }

  .contentContainer {
    margin-bottom: 4px;
    font-size: 12px;

    .title {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 6px;
    }

    .descContainer {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .content {
      margin-bottom: 8px;
      line-height: 150%;
      // flex-direction: row;
      // display: flex;
      // align-items: center;
      margin-left: 10px;

      span {
        background-color: rebeccapurple;
        bottom: 2.5px;
        position: relative;
        margin-right: 2px;
      }
    }

    .example {
      font-style: italic;
    }
  }
}
