@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.modalBoxPrivateKey {
      background-color: var(--alpha-chakraBox) !important;

      :global {
        .key-text {
          color: red;
        }
      }
}

.modalLabel {
  color: var(--alpha-text) !important;
}