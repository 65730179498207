@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  .errorText {
    font-size: rem(11);
    text-align: left;
    margin-top: 8px;
    color: #ff6868 !important;
    font-weight: 500;
  }
}

.inputAmountWrap {
  margin-bottom: 8px;

  :global .chakra-input__left-element {
    margin: 0;
    width: unset;
    border-right: 1px solid #5B5B5B;
  }

  :global .chakra-input__right-element {
    padding-right: 16px;
    margin: 0;
  }

  :global .chakra-form__error-message {
    font-size: 12px;
  }

  :global .chakra-input__group {
    background: #FFFFFF1A !important;
    > div {
      input {
        height: 62px;
        text-align: center;

        &:focus, &:active {
          border: 1.5px solid var(--alpha-tradeSubmit);
        }

        &::placeholder {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }

  :global .labelWrapper {
    label {
      opacity: 70%;
    }
  }
}

.amountOptions {

}

.amountOption {
  background: #FFFFFF1A;
  border-radius: 8px;
  padding: 10px;
  height: 64px;
  box-shadow: 0px 0px 12px -6px rgba(116, 200, 247, 0.16);
}

.isSelect {
  border: 1.5px solid var(--alpha-tradeSubmit);
}
