@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
// .cashOut {
//   color: #000;
// }

// .form {
//   text-align: left;
// }

// .input {
//   &:not(:last-child) {
//     margin-bottom: 16px;
//   }
// }

// .submitBtn {
//   border-radius: 40px;
//   background: var(--alpha-walletSubmitBtn);
//   height: 48px;
//   padding: 8px 60px;
//   gap: 10px;
//   text-align: center;
//   width: 100%;
//   color: var(--alpha-btn-text);
//   text-align: center;
//   font-family: var(--font-body);
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 26px;
//   letter-spacing: 0.14px;
//   margin-top: 20px;

//   &:disabled {
//     opacity: 0.6;
//   }
// }

// .codeInput {
//   > div {
//     display: flex;
//     justify-content: space-between;
//   }

//   // input {
//   //   box-shadow: 0px 0px 12px -6px #74c8f629;
//   //   // background: linear-gradient(0deg, rgba(116, 200, 246, 0.4), rgba(116, 200, 246, 0.4)), linear-gradient(0deg, #FFFFFF, #FFFFFF);
//   //   border: 1px solid var(--alpha-codeBlockBorder);
//   //   background: var(--alpha-codeBg);
//   //   width: 60px !important;
//   //   height: 60px !important;
//   //   border-radius: 8px;
//   //   font-weight: 600;
//   //   font-size: 20px;
//   //   line-height: 20px;
//   //   color: var(--alpha-text);
//   // }
//   > input {
//     height: 48px;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 20px;
//     width: 100%;
//     color: #ffffff99;
//     background: #353945;
//     border-radius: 8px;
//     padding: 10px 4px 10px 12px;

//     &::placeholder {
//       font-weight: 500;
//       font-size: 14px;
//       line-height: 20px;
//       color: #898989;
//     }
//   }
// }

// .error {
//   margin-top: 4px;

//   p {
//     font-size: 14px;
//     font-weight: 500;
//     color: #ff0000 !important;
//   }
// }

// .modalContent {
//   box-shadow: 0px 0px 1px 1px #ffffff22 !important;

//   &:global(.chakra-modal__content) {
//     background-color: #000 !important;
//     min-width: 90% !important;
//   }

//   &:global(.chakra-modal__content) {
//     background-color: #000 !important;
//     min-width: 90% !important;
//   }
// }

// .heading {
//   display: flex;
//   flex-direction: column;

//   .heading__title {
//     font-size: 18px;
//     line-height: 160%;
//     font-weight: 600;
//     margin-bottom: 16px;
//   }

//   .heading__sub_title {
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 140%;
//     text-align: left;
//   }
// }

.cashOut {
  color: #000;
}

.form {
  text-align: left;
}

.input {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.submitBtn {
  border-radius: 40px;
  background: var(--alpha-walletSubmitBtn);
  height: 48px;
  padding: 8px 60px;
  gap: 10px;
  text-align: center;
  width: 100%;
  color: var(--alpha-btn-text);
  text-align: center;
  font-family: var(--font-body);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.14px;
  margin-top: 20px;

  &:disabled {
    opacity: 0.6;
  }
}

.codeInput {
  > div {
    display: flex;
    justify-content: space-between;
  }

  input {
    box-shadow: 0px 0px 12px -6px #74c8f629;
    // background: linear-gradient(0deg, rgba(116, 200, 246, 0.4), rgba(116, 200, 246, 0.4)), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border: 1px solid var(--alpha-codeBlockBorder);
    background: var(--alpha-codeBg);
    width: 60px !important;
    height: 60px !important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: var(--alpha-text);
  }
}

.error {
  margin-top: 4px;

  p {
    font-size: 14px;
    font-weight: 500;
    color: #ff0000 !important;
  }
}
