@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.overlayPages {
  position: fixed;
  z-index: 1000;
  left: 0;
  width: 100vw;
  min-width: 100vw;
  transition: all 200ms;

  font-family: $font-alp;
  background-color: transparent;

  &.paddingTop {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }

  .overlayPages__container {
    background-color: transparent;
    height: 100%;
    width: 100%;
    position: relative;
    transition: all 200ms;
    overflow: hidden;
  }
}
