@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.followerItem {
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 16px;
  justify-content: space-between;
  gap: 21px;

  .followerItem__item {
    .followerItem__item__label {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .invitedItem__item__value {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .followerItem__item__value {
      margin-top: 2px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #ffffffd3;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 4px;
    }
  }

  .followerPlayer {
    flex: 1;
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;

    .avatar {
      flex: 0 36px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;

      &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        border-radius: 50%;
        background: #ffffff22;
        content: '';
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .followerItem__info {
      flex: 1;
      overflow: hidden;
    }
  }

  .invitedItem__item__value {
    opacity: 0.7;
  }

  .inviteControl {
    display: flex;
    gap: 21px;

    .shareTwitter {
      box-shadow: 0px 0px 16px -6px #00000014;
      background: linear-gradient(0deg, #ececec, #ececec),
        linear-gradient(0deg, #ffffff, #ffffff);
      max-height: 36px;
      border: 1px solid #ececec;
      border-radius: 1000px;
      width: 87px;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      gap: 4px;

      > span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000;
      }

      > svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .presale {
    background: var(--alpha-infoKeyContainerBg);
    border-radius: 50%;
    min-width: 20px;
    height: 20px;
    display: grid;
    place-items: center;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .followerItem__volume {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: space-between;

    div:first-of-type {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 19.6px */
    }

    div:last-of-type {
      color: #fff;
      font-size: 10px;
      line-height: 15px; /* 14px */
      opacity: 0.7;
    }
  }

  .trade {
    border-radius: 1000px;
    background: #f8f0ac !important;
    box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.08);
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
  }
}
