@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  &_scanner {
    margin-top: rem(-32px);
  }

  button {
    position: absolute;
    bottom: 0;
    background-color: var(--alpha-btn-bg) !important;
    color: var(--alpha-btn-text) !important
  }
}
