@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.imgWrapper {
  display: flex;
  justify-content: center;
}

.avatar {
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgba(255, 255, 255, 0.6);
  margin-bottom: 12px;
}

.title {
  color: #ffebc8;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 24px */
  font-family: $font-alp;
  margin-bottom: 24px;
}

.description {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  font-family: $font-alp;
  margin-bottom: 24px;

  span {
    font-weight: 700;
    color: #f8f0ac;
  }
}

.success_wrapper,
.error_wrapper {
  position: relative;
  max-width: 268px;
  margin-left: auto;
  margin-right: auto;

  .background {
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    width: 100%;
    padding: 0 28px;
    position: absolute;
    bottom: 36px;
  }
}

.error_wrapper {
  max-width: 100%;
}

.errorImgWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.errorImg {
  width: 190px;
}
