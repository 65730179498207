@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.stickerWebp {
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
  }
}
