@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.wrapper {
    display: flex;
    flex-direction: column;
    p {
        color: white;
    }
    span {
        font-weight: bold;
    }
    .titleHeader {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 12px;
        color: var(--alpha-pointNumber) !important;
        font-family: $font-alp;
    }
    .contentContainer {
        margin-bottom: 4px;
        font-size: 16px;
        font-family: $font-alp;
        .titleHeader {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 6px;
        }
        .title {
            font-size: 16px;
            margin-bottom: 6px;
        }
        .descContainer {
            margin-left: 12px;
            margin-top: 12px;
            margin-bottom: 12px;
        }
        .content {
            margin-bottom: 8px;
            line-height: 150%;
        }
        .example {
            font-style: italic;
        }
    }
}

.playButton {
    height: 56px;
    flex-direction: row;
    display: flex;
    border-radius: 56px;
    align-items: center;
    justify-content: center;
    background: var(--alpha-pointNumber) !important;
    width: 100%;
    margin-top: 16px;
    p {
        color: #000;
        font-size: 18px;
        font-weight: 700;
    }
    span {
        color: #000 !important;
        font-size: 14px;
        margin-top: 2px;
        margin-left: 2px;
    }
}