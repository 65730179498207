@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.title {
  margin-bottom: 8px;
  color: #fff;
  font-family: $font-alp;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  .receiverName {
    color: #f8f0ac;
    font-family: $font-alp;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.loading {
  width: 100%;
  height: 20vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.description {
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.7);
  font-family: $font-alp;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  span {
    color: #f8f0ac;
  }
}

.notEnoughBTC {
  margin-top: 20px;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  color: #f8f0ac;
  font-family: $font-alp;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  span {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.sendBtn {
  margin-top: 20px;
  padding: 8px 12px;
  width: 100%;
  color: #000;
  text-align: center;
  font-family: $font-alp;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  border-radius: 4px;
  background: #f8f0ac;
}

.errorMessage {
  color: #ff4747;
  text-align: left;
  font-family: $font-alp;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 8px;
}

.listItem {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 69px);
  grid-gap: 9px;
  justify-content: space-between;

  .item {
    width: 60px;
    border: 1px solid transparent;
    &_yield {
      padding: 4.5px;
      padding-bottom: 0;
      min-width: 60px;
    }
  }

  .active {
    border-radius: 8px;
    border: 1px solid #f8f0ac;
  }

  .price {
    margin: 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;

    span {
      color: #f8f0ac;
      font-family: $font-alp;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }
  }
}

.tokenBalance {
  display: flex;
  justify-content: center;
  padding: 16px 0 0;

  .ownerName {
    display: inline-block;
    padding: 0 4px;
    color: #ffe899;
  }
}
