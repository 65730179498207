@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.cta_btn {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;

  button {
    height: 40px;
    flex: 1;
    font-family: $font-alp;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px; /* 185.714% */
    letter-spacing: 0.14px;
    border-radius: 40px;
  }

  .cancel {
    background: rgba(255, 255, 255, 0.1);
  }

  .block {
    background: #f8f0ac;
    color: #000;
  }
}
