// GENERATIVE VILLAGE
#location-0:hover {
  [id^='village_homepink'] path {
    animation: flicker 10s infinite;
  }
  [id^='village_homered'] path {
    animation: flicker 10s infinite;
    animation-delay: 1s;
  }
  [id^='village_homegray'] path {
    animation: flicker 10s infinite;
    animation-delay: 1s;
  }
  [id^='village_homeblue'] path {
    animation: flicker 10s infinite;
    animation-delay: 0.5s;
  }
  [id^='village_cloud'] {
    animation: cloudRise 5s infinite;
  }
}

// PERCEPTRONS SQUARE
#location-1:hover {
  #perceptrons_light {
    path:nth-child(odd) {
      animation: blink 1s infinite alternate;
    }
    path:nth-child(even) {
      animation: blink 1.5s infinite alternate;
    }
  }
  [id^='Vector_67'] {
    animation: blinkOpacity 1s infinite;
  }
}

// DEFI DISTRICT
#location-2:hover {
  [id^='defi_green'],
  [id^='defi_red'] {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 2s ease-in forwards infinite;
  }

  #Group_8,
  #Group_11 {
    opacity: 0;
  }
}

// FANTASY LAND
#location-3:hover {
  [id^='fantasy_tron'] {
    animation: fantasyPlanet 10s infinite linear alternate;
  }

  [id^='fantasy_right'] {
    transition: all ease;
    animation: fantasyBlink 2s infinite ease;
  }

  [id^='fantasy_left'] {
    transition: all ease;
    animation: fantasyBlink 2.5s infinite ease;
  }
}

// DAPPS VALLEY
#location-4 {
  [id^='dapps_1'],
  [id^='dapps_2'],
  [id^='dapps_3'] {
    transition: all ease 1s;
  }

  &:hover {
    [id^='dapps_1'],
    [id^='dapps_2'],
    [id^='dapps_3'] {
      animation: float 2s infinite ease-in-out alternate;
    }

    [id^='dapps_1'] {
      animation-delay: 300ms;
    }
    [id^='dapps_2'] {
      animation-delay: 600ms;
    }
    [id^='dapps_3'] {
      animation-delay: 900ms;
    }
  }
}

// INDUSTRY ZONE
#location-5:hover {
  #industry_light path {
    animation: flicker 7s infinite;
  }
}

// NFT BAZAAR
#location-6:hover {
  #nft_gai {
    animation: nftGirl 3s infinite ease-in-out alternate;
  }
  #nft_trai {
    animation: nftBoy 3s infinite ease-in-out alternate;
  }
  #nft_coin {
    animation: nftCoin 2s infinite ease-in-out alternate;
  }
}

// UNEXPLORED LAND
#location-7:hover {
  #land_cloud {
    animation: mist 8s infinite alternate;
  }
}

// GM_TOKEN
#location-8:hover {
  #gm_coin {
    > g {
      animation: float 2s infinite ease-in-out alternate;
    }
    g:nth-child(1) {
      animation-delay: 300ms;
    }
    g:nth-child(2) {
      animation-delay: 600ms;
    }
    g:nth-child(3) {
      animation-delay: 900ms;
    }
    g:nth-child(4) {
      animation-delay: 1.2s;
    }
  }
  #gm_chimred {
    animation: bounce-top 2s both infinite;
  }
  #gm_chimxanh {
  }
}

#location-9:hover {
}

#location-10:hover {
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes flicker {
  0%,
  19.999%,
  22%,
  62.999%,
  64%,
  64.999%,
  70%,
  100% {
    opacity: 0.99;
    fill: yellow;
  }
  20%,
  21.999%,
  63%,
  63.999%,
  65%,
  69.999% {
    opacity: 0.4;
    fill: #81c9a1;
  }
}

@keyframes cloudRise {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(-15px);
    opacity: 0;
  }
}

@keyframes blink {
  0%,
  100% {
    fill: #ff8d59;
  }
  50% {
    fill: white;
  }
}

@keyframes blinkOpacity {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

@keyframes fantasyBlink {
  0%,
  100% {
    // fill: white;
    fill: currentColor;
  }
  50% {
    fill: #2596be;
  }
}

@keyframes fantasyPlanet {
  0% {
    translate: 0;
  }
  25% {
    translate: 25px;
  }
  60% {
    translate: 10px;
  }
  100% {
    translate: -10px;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes nftGirl {
  to {
    transform: translateY(-10px);
  }
}
@keyframes nftBoy {
  to {
    transform: translateX(-10px);
  }
}
@keyframes nftCoin {
  to {
    transform: translateX(30px);
  }
}

@keyframes mist {
  to {
    transform: translateX(-100px);
  }
}

@keyframes bounce-top {
  0% {
    transform: translateY(-30px);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    transform: translateY(-20px);
    animation-timing-function: ease-in;
  }
  65% {
    transform: translateY(-12px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(-6px);
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(-4px);
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}



