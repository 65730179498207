@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.jamFloating {
  position: fixed;
  z-index: 999999; // max index
  bottom: 10%;
  right: 20px;

  width: 58px;
  height: 58px;
  overflow: hidden;
  border-radius: 50%;

  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  padding: 1px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;

  &.active {
    opacity: 0.75;
  }

  &.jamFloatingActivated {
    display: block;
  }

  &.jamFloatingDisabled {
    display: none;
  }

  .container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .avatar {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .calling {
      z-index: 10;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}