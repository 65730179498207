@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.invitedItem {
  display: flex;
  padding-top: 16px;

  .invitedItem__item {
    &:nth-child(1) {
      flex: 0 60%;
      text-align: left;
      max-width: 60%;
      overflow: hidden;
    }

    &:nth-child(2) {
      flex: 0 40%;
      text-align: right;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .invitedItem__item__label {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--alpha-text);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .invitedItem__item__value {
      margin-top: 2px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: var(--alpha-text);
      opacity: 0.7;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 4px;
    }
  }

  .invitedPlayer {
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;

    .avatar {
      flex: 0 48px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .invitedPlayer__info {
      flex: 1;
      overflow: hidden;
    }
  }
}
