@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  position: relative;
  background-color: #6a6a6a;
  border-radius: 100px;
  overflow: hidden;
  svg {
    width: 100%;
    height: 100%;
  }

  &__circle {
    border-radius: 100px;
  }
}
