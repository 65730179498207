@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .coin {
    display: flex;
    justify-content: center;

    svg {
      width: 14px;
      height: 14px;
      fill: #fff;
    }
  }

  .avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar {
      width: 100%;
      height: 100%;
      border-width: 1.5px;
      border-style: solid;
      border-color: #fff;
    }
  }
}
