@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.mintMessageModal {
  position: relative;
  padding-bottom: 12px;
}

.loading {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: #fff;
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4; /* 24px */
  font-family: $font-alp;
  margin-bottom: 12px;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffffb2;
}

.listingOpenseaMessage {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.boxMessage {
  max-height: 200px;
}

.textMessage {
  margin-top: 20px;
  background: #ffffff1a;
  padding: 8px 12px;
  border-radius: 8px;
  min-height: 100px;
  overflow: auto;
  max-height: 200px;

  * {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.imageMessage {
  height: 200px;
  min-height: 200px;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #000;
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
    margin: 0 auto;
  }
}

.seperator {
  margin-top: 20px;
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #ffffff26;
}

.mintEstimation {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .mintEstimationLabel {
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    color: #fff;
    opacity: 0.7;
  }

  .mintEstimationValue {
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .mintEstimationField {
    flex: 1;
    display: flex;
    flex-direction: column;

    .mintEstimationField__name {
      display: flex;
      justify-content: space-between;
    }
  }
}

.mintActions {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  gap: 12px;

  .mintActionsCancel {
    flex: 1;
    border-radius: 40px;
    padding: 8px;
    background: #ffffff1a;
    text-align: center;

    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
  }

  .mintActionsMint {
    flex: 1;
    border-radius: 40px;
    padding: 8px;
    background: #f8f0ac;
    text-align: center;

    color: #000000;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
  }

  .mintActionsMintDisabled {
    opacity: 0.7;
  }

  .mintActionsMinted {
    flex: 1;
    border-radius: 40px;
    padding: 8px;
    background: #f8f0ac7b;
    text-align: center;

    color: #0000007b;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
  }
}

.tokenBalance {
  display: flex;
  justify-content: center;
  padding: 16px 0 0;

  .ownerName {
    display: inline-block;
    padding: 0 4px;
    color: #ffe899;
    display: flex;
    align-items: center;
  }
}

.reverseError__title {
  display: flex;
  justify-content: center;
  padding: 16px 0 0;
  font-size: 14px;
}

.reverseError__description {
  display: flex;
  justify-content: center;
  padding: 16px 0 0;
  text-align: center;
}
