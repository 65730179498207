@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
@import '~bootstrap/scss/bootstrap';
@import '_root';
@import '_font';
@import '_utils';
@import '_reset';
@import '_theme';
@import '_animation';
@import 'root';
@import '~slick-carousel/slick/slick.css';
@import '_nprogress';

.modal-backdrop {
  opacity: 0.7 !important;
}
.ant-select-item-option-content {
  font-size: 12px;
}


