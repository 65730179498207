@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.contentWrapper {
  text-align: justify;
  //max-height: 450px;
  overflow-y: auto;

  :global .section-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    color: #000000;
    //margin-bottom: 10px;
    text-align: left;
  }
  :global .section-desc {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    text-align: justify;
    //font-family: var(--ubuntu);
  }

  :global .faq-question {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    &.faq-open {
      color: #F8F0AC;
    }
  }

  :global .faq-answer {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: unset;
  }

  :global .chakra-accordion__button {
    &:focus-visible {
      box-shadow: none;
    }
  }

  :global .font-italic {
    font-style: italic;
  }

  //p {
  //  margin-bottom: 8px;
  //}

  ul {
    margin-left: 32px;
  }
  
  @media screen and (max-width: 768px) {
    //padding: 0 5px;
  }
}

.container {

}

.modalContainer {
  padding: rem(40) rem(40);
  max-height: 570px;
  border:8px solid #0000;
  border-radius: 4px;
  background:
          linear-gradient(180deg, #14003A -8.97%, #17003E 14.89%, #20004A 38.75%, #2E005F 63.6%, #42007B 88.46%, #44007E 90.45%) padding-box,
          linear-gradient(102.35deg, #CFA02A 2.3%, #F4EFDA 22.34%, #8B5304 51.11%, #F4EFDA 75.77%, #CFA02A 100.95%) border-box;

  :global {
    .chakra-modal__close-btn {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding: rem(20) rem(20);
  }
}

.btnMenuBar {
  min-width: 100px;
  height: 80%;
  position: relative;
  cursor: pointer;
}