@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.drawer_content {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.backAction {
  svg,
  path {
    stroke: #fff;
  }
}

.group_name {
  color: #fff;
  font-size: 16px;
  font-family: $font-alp;
  font-weight: 600;
  line-height: 150%; /* 24px */
  text-align: center;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  span:first-of-type {
    max-width: 250px;
    @include text-ellipsis;
  }
}
