@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.shareInfo {
  flex: 0;
  padding: 16px 0 0 !important;
  display: flex;
  flex-direction: column;
  // background: #a1e3cb;
  background-color: #212020;
  position: relative;
  // border-bottom: 1px solid #2a70d9;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: #ffffff80;
  }

  .heading {
    padding: 0 16px;
    display: flex;
    gap: 4px;
    flex-direction: column;

    .heading__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      // color: #000;
      color: #ffffff;

      > div {
        display: inline-block;
        line-height: 1;

        svg {
          transform: translateY(0.35em);
        }
      }

      strong {
        color: #00a000;
      }
    }

    .heading__sub_title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      // background: #E1F9EC;
      padding: 8px 0;
      border-radius: 100px;

      .heading__sub_title__highlight {
        font-weight: 600;
      }
    }
  }

  .codes {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 12px;
    padding: 0 16px;

    .codes__block {
      flex: 1;
      padding: 12px;
      display: flex;
      // justify-content: space-between;
      justify-content: center;
      overflow: hidden;
      gap: 12px;
      cursor: pointer;

      border-radius: 100px;
      // border: 1px solid rgba(255, 255, 255, 0.6);
      background: #fff;
      // box-shadow: 0px 0px 12px -6px rgba(116, 200, 246, 0.16);

      &:active {
        opacity: 0.8;
        // border: 1px solid rgba(255, 255, 255, 0.8);
      }

      .codes__block__label {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #000;
      }

      .codes__block__value {
        overflow: hidden;
        display: flex;
        gap: 4px;

        > span {
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          color: #000;
          // max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > svg {
          min-width: 12px;
          min-height: 12px;
        }

        > svg,
        path {
          stroke: #000;
        }
      }
    }

    .codes__between {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #00000057;
    }

    .codes__link {
      svg {
        path {
          stroke: #ffffffd3;
        }
      }

      > span {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    .codes__share {
      flex: 0 25%;
      display: flex;
      gap: 4px;
      justify-self: center;
      align-items: center;
      padding: 4px 10px;
      height: 36px;
      background: #f8f0ac;

      > span {
        text-align: center;
        display: inline-block;
        // width: 100%;
        // color: #4185ec !important;
        // color: #ffffffd3;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 12px !important;
      }

      .codes__share__icon {
        width: 16px;
        height: 12px;

        > svg {
          width: 100%;
          height: 100%;

          path {
            fill: #000;
          }
        }
      }

      // &.tweet {
      //   background: #4c9eeb;
      //   > span {
      //     color: #ffffff !important;
      //   }
      // }
    }
  }

  .earningRewards {
    // padding: 0 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    // margin-top: 20px;
    // padding-bottom: 16px;
    cursor: pointer;

    &:active {
      color: #ffffffd3;
    }
  }

  .earningRewards__block {
    display: flex;
    align-items: center;
    gap: 4px;

    .earningRewards__block__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: rgba($color: #000000, $alpha: 0.7);
      text-align: center;
      user-select: none;
      white-space: nowrap;
    }

    .earningRewards__block__amount {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #000;
      text-align: center;
      user-select: none;
      white-space: nowrap;
    }

    .earningRewards__block__amountUSD {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: rgba($color: #000000, $alpha: 0.7);

      text-align: center;
      user-select: none;
      white-space: nowrap;
    }
  }

  .earningRewards__total {
    .earningRewards__block__amount {
      color: #000;
      // text-decoration: underline;
      text-align: left;
    }

    svg {
      path {
        fill: var(--alpha-btn-text);
      }
    }
  }

  .earningRewards__invited {
    cursor: pointer;
    user-select: none;

    .earningRewards__block__title {
      text-align: left;
    }

    .earningRewards__block__amount {
      text-align: left;
      // text-decoration: underline;
    }
  }

  .earningRewards__referCode {
    > svg,
    path {
      stroke: #000;
    }
  }

  .rewardClaiming {
    display: flex;
    background: #ffffff;
    border-radius: 100px;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &:active {
      background: #ffffffd3;
    }

    &.rewardClaiming_inactive {
      background: #ffffff33;
      color: #ffffff;
    }

    > span {
      user-select: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      color: #000;
    }
  }

  .invite_code {
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    gap: 4px;
    // border-top: 1px solid rgba(255, 255, 255, 0.3);

    .invite_code_text {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #fff;
    }

    .invite_code_hl {
      color: #f8f0ac;
    }

    .invite_code_underline {
      text-decoration: underline;
    }
  }
}

.yourReferralCode {
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  gap: 4px;

  .yourReferralCode__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    // color: rgba($color: #000000, $alpha: 0.7);
    color: #fff;
    text-align: center;
    user-select: none;
    white-space: nowrap;
  }

  .yourReferralCode__amount {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    // color: #000;
    color: #fff;
    text-align: center;
    user-select: none;
    white-space: nowrap;
  }

  svg {
    path {
      stroke: #fff;
    }
  }
}


.modal {
}
