@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.inputWrapper {
  position: relative;
  margin-bottom: 12px;
  color: #2d2d2d;
  &.inputWrapperDark {
    color: white;
    input,
    textarea {
      background-color: #000000;
      color: var(--alpha-text) !important;
    }
    // .labelWrapper {
    //   color: #dedfe5;
    //   padding: 0px 2px;
    //   background-color: #1a1a1a;
    // }
  }
  .labelWrapper {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--alpha-text);
    margin-bottom: 8px;
  }
  :global .labelRightWrapper {
    span {
      color: #ababab;
    }
  }

  :global .chakra-menu__menu-button {
    height: 50px;
    background: #f4f5f6;
    border-radius: 8px;
    padding: 15px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    //line-height: 132%;

    color: #000000;
    &:hover,
    &:active,
    &:focus {
      background: #f4f5f6;
      color: #000000;
    }
    .dropdown-placeholder {
      color: #b3b3b3;
    }
    &:disabled {
      cursor: no-drop;
      color: #b3b3b3;
    }
  }
  :global .chakra-menu__menu-list {
    /* text/white */

    background: #ffffff;
    /* shadow 1 */

    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    button {
      background: #ffffff;
      color: #000000;
      background-color: transparent;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      /* identical to box height, or 20px */
      &:hover,
      &:active,
      &:focus,
      &.item-active {
        background-image: linear-gradient(
          242deg,
          #f4f5f6 0%,
          #f4f5f6 0%,
          #f4f5f6 100%
        );

        /* text / black */

        color: #000000;
      }
    }
  }

  :global .chakra-input__group {
    align-items: center;
  }

  :global .chakra-input__left-element {
    height: 100%;
    z-index: 1 !important;
  }
  :global .chakra-input__right-element {
    height: 100% !important;
    z-index: 1 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .btnMax {
      padding: 0 10px !important;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 1);
      font-size: 10px;
    }
  }

  :global .react-datepicker-popper {
    z-index: 999 !important;
    .react-datepicker {
      background: rgb(39, 38, 44);
      border-color: rgb(56, 50, 65);
      color: rgb(244, 238, 255);
      .react-datepicker__triangle {
        &::before,
        &::after {
          border-bottom-color: rgb(56, 50, 65) !important;
        }
      }
      .react-datepicker__header {
        background: rgb(55, 47, 71);
        border-color: rgb(56, 50, 65);
      }
      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header,
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: rgb(244, 238, 255);
        &:hover,
        &:focus,
        &:active {
          background-color: rgb(56, 50, 65);
        }
      }
      .react-datepicker-time__input {
        background: #413e4b;
        border-radius: 8px;
      }
      .react-datepicker__day--disabled {
        color: #aaa !important;
      }
    }
  }

  :global .form-radio-group {
    .chakra-stack {
      > div {
        flex: 1;
        width: 100%;
        background: #f4f5f6;
        border-radius: 8px;
      }
    }
  }

  :global .chakra-form-control {
    width: 100%;
    .chakra-form__label {
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        /* identical to box height, or 17px */

        text-align: right;

        color: #ababab;
        b {
          color: #000000;
        }
      }
    }
    .field-note {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      /* or 17px */

      /* text/grey 2 */

      color: #999999;
      margin-top: 8px;
      padding-left: 4px;
      padding-right: 4px;
    }
    .btn-max {
      border: 1px solid #000000;
      border-radius: 8px;
      background: transparent;
      color: #000000;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      height: 31px;
      padding: 12px;
      text-transform: uppercase;
      &:hover {
        opacity: 0.8;
        background: #000000;
        color: #ffffff;
      }
    }
    :global .w-md-editor {
      // background-color: transparent;
      .w-md-editor-toolbar {
        // background-color: transparent;
      }
    }
  }
}

.formContainer {
  background: #f4f5f6;
  align-items: center;
  border-style: solid;
  border-color: #000000;
}

.formControl {
  flex: 1;

  :global {
    .chakra-numberinput {
      input {
        border: none;
        box-shadow: none;
      }
    }
  }
  input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    padding: 15px 16px;
    font-weight: 500;
    //line-height: 132%;
    font-size: 20px;
    color: var(--alpha-text);
    text-align: left;
    border: none;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }

    &::placeholder {
      color: #b3b3b3;
    }
    &:focus-visible {
      outline: 0;
      border: none;
      box-shadow: none;
    }

    &:disabled {
      opacity: 1;
    }
  }
  :global {
    .w-md-editor-preview {
      p {
        color: inherit;
      }
    }
  }
  textarea {
    height: 100%;
    width: 100%;

    &::placeholder {
      color: #b3b3b3;
    }
  }
}

.searchBox {
  position: relative;
  background-color: black;
  input {
    height: 32px;
    width: calc(100% - 20px);
    margin: 0px 10px;
    padding: 5px;
    line-height: 32px;
    font-size: 14px;
    outline: none;
    border-radius: 5px;
    &:focus,
    &:hover {
      border-width: 2px;
      border-color: #4878ff;
      outline: #4878ff;
    }
  }
  i {
    position: absolute;
    font-size: 12px;
    top: 50%;
    color: #6f6c6c;
    right: 10px;
    transform: translate(-60%, -50%);
  }
}

.borderDanger {
  border-color: #c22a32 !important;
  .flag-dropdown {
    border-color: #c22a32 !important;
  }
}

.errorMessageWrap {
  z-index: 1401;
  div {
    &:last-child {
      background-color: #c22a32;
      font-size: 12px;
    }
    &::before {
      border-bottom-color: #c22a32 !important;
    }
  }
}

.dropdownContainer {
  button {
    padding: 8px;

    /* Background/Background grey 1 */

    background: #f4f5f6;
    border-radius: 8px;
    height: 50px;
    font-weight: 500;
    color: #353945;
  }
}

.formCalendar {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding: 15px 16px;
  input {
    width: 100%;
  }
}

.formSelect {
  input {
    display: none;
  }
}
