@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.wrapper {
  margin-left: -10px !important;
  margin-right: -10px !important;

  * {
    font-family: $font-alp;
  }

  :global(.chakra-modal__content) {
    width: 90vw !important;
  }

  .topup_img_wrapper {
    width: 140px;
    height: 140px;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .img_wrapper {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .block_wrapper {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--alpha-walletBlockBorder);
    background: var(--alpha-walletBlockBg);
    box-shadow: 0px 0px 20px -6px var(--alpha-blockWrapperShadow);
    margin-bottom: 16px;

    :global {
      .chakra-text {
        color: var(--alpha-text);
      }
    }

    .btnMobile {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #f8f0ac;
      border-radius: rem(100);
      padding: rem(12) rem(24);
      margin-top: rem(12);
      cursor: pointer;
      p {
        font-size: rem(14);
        line-height: rem(20);
        font-weight: 500;
        color: #000000;
      }
    }
  }
}

.divider {
  margin-top: 24px;
  margin-bottom: 24px;
  position: relative;
  text-align: center;

  span {
    color: #000;
    text-align: center;
    font-family: var(--font-body);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    z-index: 1;
    position: relative;
  }

  &::before {
    content: ' ';
    display: block;
    width: 100%;
    height: 0;
    border-top: 1px solid #ececec;
    left: 0;
    top: 50%;
    position: absolute;
  }
}

.qrWrapper {
  .qrCode {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }

  .text {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }
}

.loading {
  width: 98%;
  height: 98%;
  background-color: rgba($color: #fff, $alpha: 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
}

.copy_icon {
  margin-left: 8px;
  cursor: pointer;
  // update svg color
  path {
    stroke: #f8f0ac;
  }
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: left !important;
  gap: 24px;
  color: black !important;
  font-size: 16px !important;
  padding-left: 16px !important;
  img {
    width: 28px;
  }
}
