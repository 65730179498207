@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  height: 100svh;
  width: 100vw;
  position: fixed;
  background: rgba(#212121, 0.5);
  top: 0;
  left: 0;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
