@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.controllers {
  // background-color: #000;
  flex: 1;
  width: 100vw;
  position: fixed;
  bottom: 0;

  * {
    font-family: $font-alp;
  }

  .requested_wrapper {
    // padding: 0 48px;
    min-height: 75px;
    background: #212121;
    display: flex;
    justify-content: center;

    .request_box {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      padding: 8px 12px;
      border-radius: 12px;
      background: linear-gradient(90deg,
          rgba(0, 245, 160, 0.3) 0%,
          rgba(0, 217, 245, 0.3) 100%);
      transition: all 0.2s linear;

      &:not(:first-of-type) {
        opacity: 0;
        pointer-events: none;
      }
    }

    .requested_info {
      font-size: 12px;
      font-weight: 500;
      line-height: 140%;
      /* 16.8px */
      white-space: nowrap;

      span {
        color: #f8f0ac;

        @include text-ellipsis;
        max-width: 130px;
      }
    }

    .request_cta {
      display: flex;

      >button {
        flex: 1;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        text-transform: uppercase;
      }

      .decline {
        color: #ff8a8a;
      }

      .accept {
        background: var(--stroke,
            linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .heading_wrapper {
    display: flex;
    padding: 12px 24px;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background: #212121;
    gap: 24px;
    padding-bottom: 100px;

    .end_call {
      padding: 12px;
      border-radius: 100px;
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      /* 19.6px */
      font-family: $font-alp;
      height: 48px;
      width: 130px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .mute_controller {
      padding: 12px;
      border-radius: 100px;
      background: rgba(248, 240, 172, 0.1);
      // color: #f8f0ac;
      font-family: $font-alp;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      /* 19.6px */
      height: 48px;
      width: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.6);



      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        span {
          min-width: 50px;
        }

      }

      &.muted {
        // background: #fff;
        background: #fff;
        color: #000;
      }

      &:not(.muted) {
        color: #fff;

        svg,
        path {
          fill: rgba(255, 255, 255, 1);

        }
      }

      &.request_btn {
        width: fit-content;

        .request_sent {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
    }
  }



  // .mute_controller {
  //   button {
  //     display: flex;
  //     align-items: center;

  //     gap: 4px;
  //     height: 25px;
  //     background: #f8f0ac;
  //     padding: 0 12px;
  //     color: #000;
  //     font-size: 12px;
  //     line-height: 140%; /* 16.8px */
  //     font-family: $font-alp;
  //     border-radius: 14px;
  //     width: 81px;
  //     transition: all 0.2s linear;

  //     span {
  //       display: flex;
  //       justify-content: center;
  //       flex: 1;
  //     }
  //   }

  //   &.muted {
  //     button {
  //       background: #fff;
  //     }
  //   }
  // }

  .user_list {
    padding: 20px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.15);

  &::-webkit-scrollbar {
    display: none;
  }

  .loading {
    height: 50vh;
    width: 100vw;
    position: absolute;
    background-color: #212121;
    z-index: 100;
    display: grid;
    place-items: center;

    p {
      color: #fff;
      margin-top: 8px;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.listItem {
  flex: 1;
  // overflow: hidden;
  max-height: calc(100vh - 235px - 48px);
}

.listParticipant {
  max-height: calc(100vh - 235px - 48px);
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 12px;
  justify-content: center;
  gap: 12px;

  &::-webkit-scrollbar {
    display: none;
  }

  >div {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 12px;
    aspect-ratio: 1/1;
    align-items: center;
    width: calc(25% - 12px);
    min-width: 90px;

  }

  &.speakers {
    background: #ffffff1a;
  }

  &.speakers {
    background: #ffffff1a;
  }
}

.heading {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  line-height: 140%;
  /* 22.4px */
  border-radius: 100px;
  background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
  width: fit-content;
  font-family: $font-alp;

  span {
    font-weight: 700;
  }

  &.audience {
    background: #ffffff33;
    color: white;
  }
}

.tooltip {
  padding: 8px 32px;
  border-radius: 8px;
  background: #fff;
  color: #000;
  font-family: $font-alp;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
}

.approve_btn {
  background: #f8f0ac;
  border-radius: 100px;
  padding: 6px 12px;
  color: #000;
}

.permission_wrapper {
  background: rgba(255, 255, 255, 0.10);
  padding: 12px 20px;
  display: flex;
  gap: 16px;

  .permission_content {
    color: #FFF;
    font-family: $font-alp;
    font-size: 14px;
    line-height: 140%;
    opacity: 0.7;
    /* 19.6px */
  }
}