@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.answer {
  white-space: break-spaces;
  ul,
  ol {
    padding-left: 20px;
  }
  li {
    margin: 12px 0;
  }
}
