@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  background: var(--alpha-switchHolding);
  border-radius: 100px;
  width: 280px;
  padding: 8px;
  border: 1px solid #30323C;

  >div {
    font-size: rem(14);
    font-weight: 500;
    color: var(--alpha-text);
    //width: 50px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    flex: 1;
    border-radius: 100px !important;

    &.buy {
      color: var(--alpha-btn-text);
      background: var(--alpha-btn-bg);
    }

    &.sell {
      color: var(--alpha-btn-text);
      background: var(--alpha-btn-bg);
    }
  }
}