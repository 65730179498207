@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  * {
    font-family: $font-alp;
  }

  .name {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #ffffff;

    &_twitterName {
      display: flex;
      align-items: center;
      gap: 5px;

      > span {
        @include fixTextLargeLen(220px);
        display: block;
      }
    }

    &_metadata {
      display: flex;
      gap: 4px;
      margin-left: 10px;

      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      /* 15.6px */

      &_value {
        font-weight: bold;
      }

      &_label {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .userName {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #ffffff;
    opacity: 0.7;
  }

  .balance_info_byTC {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: var(--chakra-colors-blue-600);
  }

  .balance_info_keyprice {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    opacity: 0.7;
  }

  .btn_dm {
    height: rem(36px);
    width: rem(36px);
    background: rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.39px;
    gap: 12px;
  }

  .btn_minimum_key {
    // height: 36px;
    // background: rgba(255, 255, 255, 0.15);
    // border-radius: 1000px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // color: white;
    // text-transform: uppercase;
    // font-size: 13px;
    // font-style: normal;
    // font-weight: 500;
    // line-height: 100%;
    // letter-spacing: -0.39px;
    // gap: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 15px;
    height: 36px;
    justify-content: space-between;
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.15);
    color: #fff !important;
  }

  .btn33 {
    border-radius: 100%;
    width: 28px;
    min-width: 28px;
    height: 28px;
    line-height: 100%;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
  }

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    opacity: 0.6;
  }

  .value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
  }

  .twDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    color: rgba(255, 255, 255, 0.7);

    a {
      color: #ffffff;
      font-weight: 500;
    }
  }
}

.confirmModal {
  background: var(--alpha-confirmModalBg) !important;

  :global {
    .chakra-modal__header {
      font-size: rem(26);
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .btnConfirm {
    width: 100%;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: rem(18);
    min-height: 50px;
    color: var(--alpha-btn-text);
  }

  .confirmLabel {
    color: var(--alpha-notiChakra);
  }
}

.btnBack {
  display: flex;
  align-items: center;
}

.groups_member {
  display: flex;
  align-items: center;
  gap: 12px;
}

.renderGroupMembers_sub {
  align-items: center;

  * {
    font-size: 13px !important;
  }
}

.wrapButtons {
  margin-left: auto;
  width: calc(100% - 32px);
}

.membersLabel {
  position: relative;

  img,
  svg {
    aspect-ratio: 1/1 !important;
    object-fit: contain !important;
    height: 14px;
    width: 14px;
  }

  &_item {
    display: flex !important;
    align-items: center !important;
    gap: rem(8px);
    line-height: 1;
  }
}

.tooltip_member {
  border-radius: 12px;
  background: #292929;
  box-shadow: 0px 0px 24px -6px rgba(0, 0, 0, 0.12);
  padding: 11px 16px;
  position: absolute;
  width: max-content;
  z-index: 2;
  right: 0;
  top: calc(100% + 5px);

  &_item {
    display: flex !important;
    align-items: center !important;
    gap: rem(8px);
    white-space: nowrap;
    padding-top: 5px;
    padding-bottom: 5px;

    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.14px;

    svg,
    img {
      aspect-ratio: 1/1 !important;
      object-fit: contain !important;
      height: 20px;
      width: 20px;
    }
  }
}

.maxLen {
  display: inline-block;
  @include fixTextLargeLen(130px);
}

.menuWrap {
  display: flex;
  justify-content: flex-end;
  margin-right: -6px;

  &_item {
    width: rem(32px);
    height: rem(32px);
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    &__dm {
      line-height: 1;
    }

    > span {
      display: inline-block !important;
      width: fit-content !important;
      flex: 0 0 auto;
    }
  }
}

.grBtns {
  margin-left: auto;
  display: flex;
  gap: rem(8px);
  align-items: center;
}

.circleAvatar {
  position: relative;

  svg {
    width: 20px;
    height: 20px;
  }

  &_icon {
    position: absolute;
    bottom: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
  }
}

.btnSendInvitePlaceHolder {
  padding: 0 !important;
  background-color: var(--alpha-createBtnBg) !important;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  color: var(--alpha-btn-text);
  height: 36px !important;
  min-width: 83px !important;
}

.wrapBtn {
  :global {
    .btnFollow {
      justify-content: center;
      width: 100%;
      border-radius: 100px;
      background: #fff;
      color: #000000;
      padding: 0 12px !important;
      height: 28px;
      font-size: 14px;

      svg {
        display: none;
      }
    }

    .isFollowed {
      background: rgba(119, 119, 119, 0.4) !important;
      color: #ababab;

      svg path {
        fill: #ababab;
      }
    }
  }
}

.profile_avatars_cols {
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
}

.bigAvatar {
  position: relative;

  &_verify {
    position: absolute;
    bottom: 0;
    right: 5px;
    z-index: 99;
  }
}

.user_metadata {
  &_value {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    margin-bottom: 2px;
  }

  &_label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: rgba(#fff, 0.6);
    display: flex;
    align-items: center;
    gap: 3px;
    text-align: center;
    justify-content: center;
  }

  &_line {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
  }
}

.topProfile {
  transition: all 0.2s linear;
  background-color: #000000;

  &_black {
    min-width: 68px;
  }

  &_actions {
    &_circle {
      path {
        fill: #f8f0ac;
      }
    }
  }

  &_leftName {
    align-items: center;
    gap: 12px;

    .name {
      @include fixTextLargeLen(168px);
    }

    &_tw {
      line-height: 1;
    }

    &_back {
      height: 36px;
      display: flex;
      align-items: center;
    }

    .user_name {
      font-size: 13px;
      opacity: 0.7;
      @include fixTextLargeLen(168px);
    }
  }
}

.fixName{
  @include fixTextLargeLen(168px);
  color: #FFFFFF !important;
}

.profileBottom {
  margin-bottom: -13px;
  margin-top: rem(18px);
  background: #313131;
  margin-left: -12px;
  width: calc(100% + 24px);
  padding: 0 12px;
  padding-bottom: 5px;
}

.minHold {
  margin-bottom: 5px;
}

.keys_infos {
  padding: 6px 0;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;

  svg {
    flex: 0 0 24px;
  }

  &_text {
    color: rgba(255, 255, 255, 0.8);
    text-align: left;

    b {
      color: #ffffff;
      font-weight: 700;
    }
  }

  .keys_infos_text_strong {
    color: #ffffff;
    font-weight: 700;
  }
}

.fs_14 {
  * {
    font-size: 14px !important;
  }

  .value {
    color: white !important;
  }
}

.profile_actions {
  background-color: #000000;

  &.profile_actions_fixed {
    position: fixed;
    top: calc(env(safe-area-inset-top) + 62px);
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 10px;
  }

  .BtnShareTwPlaceHolder {
    padding: 0 14px !important;
    max-width: max-content;
  }
}

.drawer_content {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.btn_trade {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px !important;
  height: 32px !important;
  justify-content: center;
  font-weight: 500 !important;
  flex: 1;
  width: 100%;

  * {
    font-weight: 500 !important;
  }

  &__buy {
    flex: 1;
    background: white !important;

  }

  &__buyToken {
    padding: 0 16px !important;
  }

  &_price {
    display: flex;
    gap: 5px;
  }

  &__balance {
    background: #2c2c2c !important;

    &__text,
    &__label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }

    &__text {
      color: #fff;
    }

    &__label {
      opacity: 0.7;
    }
  }

  &_text {
    color: #000000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    display: flex;
    gap: 2px;
    align-items: center;

    &_usd {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  &_countdown {
    p {
      color: #000000 !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      display: flex;
      gap: 2px;
      align-items: center;
    }

    svg{
      height: 18px !important;
      width: 18px !important;
      path{
        fill: #0d0e12;
      }
    }
  }

  //&:hover {
  //  opacity: 1 !important;
  //}

  &[disabled] {
    opacity: 0.4 !important;
  }

  &__33 {
    background: #ffffff;

    &:hover {
      opacity: 1 !important;
      background: #ffffff !important;
    }
  }
}

.avatars_badge {
  position: absolute;
  z-index: 10;
  bottom: -5px;
  right: -5px;

  .xIcon {
    height: 20px !important;
    width: 20px !important;
  }
}

.profileMinimize {
  top: calc(env(safe-area-inset-top) - 1px);
  width: calc(100% + 32px);
  padding-left: 16px;
  padding-right: 16px;
  left: -16px;
  z-index: 20;
  background-color: #000000;
  position: fixed;
  animation: slowDown 0.5s cubic-bezier(0.25, 1, 0.5, 1);

  &_inner {
    padding: 10px 16px;
    padding-left: 0;
  }

  .topProfile_black {
    padding-left: 16px;
    padding-right: 16px;
    min-width: auto;
  }

  &_actions {
    width: 100%;
    display: flex;
    padding: 0 16px;
    margin-bottom: 16px;
    gap: 16px;

    .btn_trade__buy {
      flex: 1;
      padding: 15px 25px !important;
      height: 36px !important;
      justify-content: space-around;
    }
  }

  .name {
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      height: 12px;
      width: 12px;
    }
  }
}

@keyframes slowDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}
