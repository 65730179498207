@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.congratulation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000d9;

  .container {
    height: 360px;
    min-height: 360px;
    border-radius: 12px;
    // overflow: hidden;
    margin: 0 32px;
    aspect-ratio: 17 / 20;
    background: #292929;
    transition: all 200ms;

    .heading {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 16px;
      border-bottom: 1px solid #ffffff26;
      color: #fff;
      position: relative;

      .heading__close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;

        &:hover,
        &:active {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }

    .body {
      padding: 16px;

      .body__icon {
        display: flex;
        justify-content: center;
      }

      .body__message {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #fff;

        .body__message__highlight {
          color: #baedbd;
        }
      }

      .body__actions {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .body__button {
          border-radius: 40px;
          padding: 8px 0;
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          color: #fff;

          &:hover,
          &:active {
            cursor: pointer;
            opacity: 0.7;
          }
        }

        .body__actions__invite {
          background: #f8f0ac;
          color: #000;
        }

        .body__actions__later {
          background: #ffffff1a;
          color: #fff;
        }
      }
    }
  }
}
