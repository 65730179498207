@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  width: 80px;
  height: 80px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
