@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.videoModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  :global(.modal-dialog) {
    max-width: rem(600);

    iframe {
      pointer-events: none;
      object-fit: contain;
    }
  }

  :global(.modal-content) {
    background-color: transparent;
    border: none;
  }

  :global(.modal-header) {
    border-bottom: 0;
    // padding-top: rem(18px);
    // padding-right: rem(18px);

    button {
      position: absolute;
      border-color: $white-100;
      filter: brightness(0) invert(1);
      right: rem(5px);
      top: rem(-10px);

      @include w-min(1280px) {
        right: rem(-20px);
        top: rem(-10px);
      }
    }
  }

  :global(.btn-close) {
    border: 1px solid $border-dark;
    border-radius: 50%;
    background-size: 40%;
  }

  :global(.modal-body) {
    padding: 0;
  }
}

.content {
  position: relative;

  .closeBtn {
    display: flex;
    position: absolute;
    right: rem(16);
    top: rem(16);
    width: rem(44);
    height: rem(44);
    z-index: 1;
    cursor: pointer;
  }

  .closeBtn:hover {
    opacity: 0.8;
  }
}
