@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  :global {
    .chakra-table__container {
      min-height: 200px;
      overflow: hidden;
      // padding: 0 12px;
      width: 100%;

      .chakra-table {
        position: relative;
        table-layout: fixed;

        thead,
        tbody {
          tr {
            th,
            td {
              &:first-child {
                padding-right: 8px;
              }
              &:last-child {
                width: 30%;
                button {
                  padding-left: 0;
                  padding-right: 0;
                }
              }
              &:nth-child(2) {
                width: 25%;
              }
            }
          }
        }

        thead {
          tr {
            //check this
            th {
              padding: 12px 0px;
              border-bottom: 1px solid var(--alpha-postLine);
              background-color: var(--alpha-bg);
              position: sticky;
              top: 0;
              z-index: 1;

              &:first-child {
                padding-left: 12px;
              }

              &:last-child {
                padding-left: 8px;
                padding-right: 12px;

                > div {
                  justify-content: flex-end;
                }
              }

              @media screen and (max-width: 768px) {
                &:nth-last-child(2) {
                  > div {
                    justify-content: flex-end;
                  }
                }
              }
            }
          }
        }

        tbody {
          td {
            padding: 16px 0px;
            //border-color: transparent;
            //color: #FFFFFF;
            //font-family: var(--bebas-neue);
            //font-size: 20px;
            vertical-align: middle;
            //border-bottom: 1px solid #ECECEC;

            &:first-child {
              padding-left: 12px;
            }

            &:last-child {
              padding-left: 8px;
              padding-right: 12px;
            }
          }
        }
      }
    }

    .infinite-scroll-component__outerdiv {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .infinite-scroll-component {
        // overflow: auto;
        // height: 100%;
        // height: 100% !important;
      }
    }
  }
}

.buyer {
  color: var(--alpha-text) !important;
  line-height: 20px;
  max-height: 20px;
  @include fixTextLargeLen(150px);
}

.tokensList {
  //overflow: hidden !important;
  //height: rem(800) !important;
  //
  //@media screen and (min-height: 1300px) {
  //  height: rem(1150) !important;
  //}
}

.earnButton {
  width: 66px;
  height: 32px !important;
  padding: 8px 12px !important;
  background-color: var(--alpha-btn-bg) !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  //line-height: 20px;
  color: var(--alpha-btn-text) !important;

  &:hover {
    background-color: var(--alpha-btn-bg);
  }
}

.statusText {
  text-transform: capitalize;
  width: 80px;
  height: 32px;
  border-radius: 4px;

  &.cancelled, &.rejected {
    color: #FF4747 !important;
    //background: #FF474726 !important;
  }

  &.confirmed {
    color: #FFFFFF !important;
    //background: #FFFFFF1A !important;
  }
}

.btnActionWrapper {
  button {
    height: 40px !important;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 500;
  }
}

.btnCancel {
  width: 84px;

  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 40px;
  color: #FFFFFF !important;
}

.btnResend {
  flex: 1;
}

.modalContent {
  padding: 20px !important;
}

.dots_icon {
  fill: var(--alpha-text);
}

.btnMenu {
  height: 32px;
  border-radius: 8px;
  background: #FFFFFF;
}

.wallet_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px !important;
  // padding-bottom: 8px;
  border-bottom: 1px solid var(--alpha-menuList) !important;
  margin-bottom: 8px !important;
  background: transparent !important;
}

.keyContainer {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  align-items: center;
  justify-content: center;

  background: var(--alpha-infoKeyContainerBg);
  box-shadow: 0 0 8px -4px #000;
  border-radius: 100%;

  &.keyContainerPresale {
    // background-color: #ececed;
  }

  svg {
    width: 12px;
    height: 12px;
  }
}
