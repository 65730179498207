.toast-error {
  justify-content: center;
  gap: rem(10);

  [role='status'] {
    justify-content: flex-start;
    margin: 0;
    margin-left: rem(4);
    font-size: rem(14);
    font-weight: 500;
    width: fit-content;
  }
}

.toast-success {
  justify-content: center;
  gap: rem(10);

  [role='status'] {
    justify-content: flex-start;
    margin: 0;
    font-size: rem(16);
    font-weight: 500;
    width: fit-content;
    flex: 1;
  }
}

.container {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 rem(60px);

  @include max-tablet {
    max-width: 100%;
    padding: 0 rem(16);
  }
}

.grecaptcha-badge {
  display: none !important;
}

.is-alpha-dark {
  @media screen and (max-width: 1024px) {
    div[class*='chakra-menu__menu-list'] {
      background: var(--alpha-listMenu);
    }

    section[class*='chakra-modal__content'] {
      background: var(--alpha-listMenu);
      height: fit-content;
      max-height: 90vh;
      position: relative;
    }

    textarea[class*='chakra-textarea'] {
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    input[class*='input-container-style'] {
      color: var(--alpha-chakraInput) !important;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
.swiper {
  // display: none !important;
  .swiper-slide {
    max-height: 100%;
    // height: 200px;
    // width: 100% !important;
  }
}

.is-alpha-dark section[class*='chakra-modal__content'].donate_modal {
  // display: none !important;
  background: transparent !important;
}

.is-alpha-dark section[class*='chakra-modal__content'].tweet_detail_modal {
  max-width: 100vw !important;
  max-height: 100vh !important;
  width: 100vw !important;
  height: 100vh !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;

  .modal-header-wrap {
    display: none !important;
  }

  .chakra-modal__body {
    margin: 0 !important;
    padding: env(safe-area-inset-top) 0 0 0 !important;

    @include disable-scrollbar;
  }
}

// .is-alpha-dark
//   .chakra-modal__content-container:has(#chakra-modal-comment-action) {
//   height: calc(100vh - 80px);
// }
