@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.refreshWrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  .wrapperScroll {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100vw;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    transition: transform 0s linear;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-moz-scrollbar {
      display: none;
    }
  }
}
