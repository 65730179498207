@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {

  //margin-bottom: 12px;
  >div {
    &:first-child {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      /* identical to box height, or 20px */

      /* text/grey 2 */

      color: #1c1c1c;
      opacity: 0.7;
    }

    &:last-child {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      /* identical to box height, or 20px */

      text-align: right;

      /* text / black */

      color: var(--alpha-tcText) !important;

      >div {
        color: var(--alpha-tcText)
      }

      a {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */

        text-align: right;

        /* others / blue */

        color: #1879ec;
        cursor: pointer;
      }
    }
  }
}