.cursor-pointer {
  cursor: pointer;
}

.font-medium {
  font-weight: 500;
}

.divider {
  background-color: rgb(255 255 255 / 10%);
  height: 1px;
  width: 100%;
}

.font-bandeis {
  font-family: $font-alp !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.border-white {
  border-color: #fff !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.mb-10 {
  margin-bottom: 0.5rem;
}

.mb-12 {
  margin-bottom: 0.75rem;
}

.mb-16 {
  margin-bottom: 1rem;
}

.mb-24 {
  margin-bottom: 1.5rem;
}

.mb-150 {
  margin-bottom: rem(150px);
}

.mb-120 {
  margin-bottom: rem(80px);

  @include min-tablet {
    margin-bottom: rem(120px);
  }
}

.mb-100 {
  margin-bottom: rem(100px);
}

.hidden {
  display: none !important;
}

.image-marker {
  width: 100vw;
  height: 100vh;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

#touch_button {
  rect {
    cursor: pointer;
  }
}

.pointer-none {
  pointer-events: none;
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased !important;
  -webkit-font-smoothing: antialiased !important;
  font-size: 10px;

  //@include w-max(1023.98px) {
  //  // font-size: 8px;
  //}
  //
  //@include min-pc {
  //  @include font-calc(1200px, 1920px, 8px, 10px);
  //}
  //
  //@include min-2k {
  //  font-size: 11.5px;
  //}
}

main {
  position: relative;
}

.row {
  // --bs-gutter-y: #{rem(24px)};
  row-gap: rem(24);

  @include mobile {
    --bs-gutter-x: #{rem(10px)};
    --bs-gutter-y: #{rem(10px)};
  }
}

.top-100 {
  transform: translateY(100px) !important;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden {
  display: none;
}

.default-popover {
  border: none !important;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  border-radius: 8px !important;
}

.accordion-button-mobile {
  &:hover {
    background: transparent !important;
  }
}

.instructional_modal {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.is-alpha-dark {
  .chakra-popover__content {
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    background: rgba(255, 255, 255, 0.3);
    height: fit-content;
    max-height: 90%;
  }

  .chakra-popover__arrow {
    background: rgba(255, 255, 255, 0.3) !important;
    box-shadow: none !important;
  }

  .chakra-popover__body {
    background: var(--alpha-bg) !important;
  }

  .chakra-text {
    color: var(--alpha-text);
  }

  .chakra-button {
    color: var(--alpha-btn-text);

    .ic-copy {
      svg {
        path {
          stroke: var(--alpha-checkBoxIcon);
          fill: var(--alpha-checkBoxIcon);
        }
      }
    }
  }

  .ql-editor.ql-blank::before {
    color: var(--alpha-placeholderInputChat);
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background: var(--alpha-bg);
    color: var(--alpha-text);
  }
}

body {
  transition: background-color 0.5s;
  overscroll-behavior: none;
}

.link-display {
  display: block;
}

.link-break-lines {
  word-break: break-all;
}

.disable-scrollbar-x {
  padding-bottom: 100px;
  overflow-x: hidden !important;
}

.main-free-style {
  width: 100%;
  height: 100vh;
  position: relative;
  padding-top: calc(66px + constant(safe-area-inset-top));
  padding-top: calc(66px + env(safe-area-inset-top));
  padding-bottom: 65px;
  overflow-y: hidden;
}

.fill-black {
  svg,
  path {
    fill: #000 !important;
  }
}

.stroke-black {
  svg,
  path {
    stroke: #000 !important;
  }
}

.fill-white {
  svg,
  path {
    fill: #fff !important;
  }
}

.stroke-white {
  svg,
  path {
    stroke: #fff !important;
  }
}
