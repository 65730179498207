@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.dropFile {
  border: none;
  width: 100%;
  text-align: center;
  background-color: #f2f5fa;
  position: relative;

  .dropZone {
    padding: rem(48px);
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: pointer;
  }

  .dropZoneThumbnail {
    display: inline-block;
    margin-bottom: 6px;
    width: rem(100px);
    height: rem(100px);
  }

  .dropZoneDescription {
    font-weight: 500;
    font-size: rem(18px);
    line-height: rem(28px);
    color: $black-40;
  }

  &__drag {
    background-color: #e5ecf6;
  }

  &__error {
    border: 1px solid #ff4747;
  }

  .errorText {
    color: #ff4747;
  }

  .loadingWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgb(255 255 255 / 20%);
    backdrop-filter: blur(16px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: rem(16px);

    .loadingIndicatorWrapper {
      position: relative;
      height: rem(56px);
      width: rem(56px);
    }

    .loadingText {
      font-weight: 500;
      font-size: rem(16px);
      line-height: rem(26px);
      color: #0006;
    }
  }
}
