@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.socialShare {
  position: absolute;
  z-index: 10;
  display: flex;
  gap: rem(20);
  right: 28px;
  top: 28px;
  align-items: center;

  > a {
    width: rem(60px);
    height: rem(60px);
    background-color: #ffe8dc;
    display: grid;
    place-items: center;
    border-radius: 50%;
    box-shadow: 6px 6px 2px rgb(0 0 0 / 40%);

    &:hover {
      opacity: 0.75;
    }
  }

  svg,
  path {
    fill: #40737f;
  }

  @media screen and (max-width: 768.98px) {
    position: relative;
    display: flex;
    justify-content: center;
    right: 0;
    height: 0;
    top: 47px;

    svg {
      scale: 0.8;
    }
  }

  @media screen and (min-width: 768.98px) and (max-width: 1279.98px) {
    right: 20px;
    top: 20px;
    > a {
      width: rem(40px);
      height: rem(40px);

      svg {
        padding: 2px;
      }
    }
  }

  @media screen and (max-width: 499.98px) {
    > a {
      width: rem(50px);
      height: rem(50px);
    }
  }
}

.wrapper {
  width: 100vw;
  position: relative;
  height: 100%;

  @media screen and (max-width: 1499.98px) {
    height: 100vh;
  }

  @media screen and (max-width: 768.98px) {
    overflow: visible;
    height: auto;
  }
}

.sea_bg {
  z-index: -1;
  width: 100vw;
  height: 100%;
  position: absolute;

  > div {
    width: 100%;
    height: 100%;
  }

  object {
    width: 100%;
    height: calc(100% + 0px);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 768.98px) {
    position: absolute;
  }
}

.city_map_grid {
  display: grid;
  grid-template-columns: repeat(160, 1fr);
  grid-template-rows: repeat(90, 1fr);
  aspect-ratio: 16/9;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: 100%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // top: 50%;

  @media screen and (min-width: 1439.99px) {
    overflow: hidden;
  }

  .location {
    position: relative;
    div,
    object {
      width: 100%;
      height: 100%;
    }

    :global(.chakra-popover__popper) {
      height: auto;
    }

    object svg {
      pointer-events: none;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (min-width: 1499.99px) {
    left: unset;
    top: unset;
    transform: unset;
  }
}

.city_map_grid_mobile {
  .sea_bg {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .cityMap {
    // height: 2000px;
  }
}
.gm {
  width: auto;
  height: 6rem;
  margin-top: 1.2rem;

  @media screen and (min-width: 1499.99px) {
    height: 6.6rem;
    margin-top: 1rem;
  }
}

.cssBg {
  min-height: rem(1000);
  background: #010506;
}

.cssArcade {
  // display: flex;
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // min-height: 100vh;
  background: #000000;
}

.cssAlpha {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background: #000000;
  overflow: hidden;

  .content {
    min-height: 100vh;
    width: 100%;
    background: #000000;
  }
}

.countryBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  flex: 1;

  p {
    margin-top: 32px;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    font-family: var(--chakra-fonts-sora);
  }
}
