@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.box{

  &:before,
  &:after{
      content: "";
      position: absolute;
      transition: transform 0.25s ease-out;
  }

  /* Vertical line */
  &:before{
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      margin-left: -1px;
  }

  /* horizontal line */
  &:after{
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      margin-top: -1px;
  }
  
  &.open{
      cursor: pointer;
      
      &:before{ transform: rotate(90deg); }
      &:after{ transform: rotate(180deg); }
  }
}