@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.shareReferralTwitter {
  flex: 1;
  // background-color: #000000;
  background-color: #171717;

  .search {
    gap: 8px;
    position: sticky;
    top: 0;
    margin-bottom: 16px;
    // background-color: #000000;
    background-color: #212020;
    z-index: 2;
    padding: 16px;
    padding-bottom: 0;
  }

  .search {
    display: flex;
    gap: 16px;
    align-items: center;
    flex: 0;
    padding-right: 16px;
    background-color: #171717;

    .search__label {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #ffffffb3;
    }

    .search__input {
      flex: 1;
      border-radius: 800px;
      padding: 10px 4px 10px 12px;
      background: #ffffff26;
      position: relative;

      > svg {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        opacity: 0.6;
      }

      .search__input_loading {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        opacity: 0.6;
      }

      > input {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        color: #ffffff99;
        padding: 0 12px;
        padding-left: 20px;

        &::placeholder {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff99;
        }
      }
    }
  }

  .list {
    flex: 1;
    position: relative;
    overflow: hidden;
    height: 100%;

    .list__loading {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .list_items {
      flex: 1;
      height: 100%;
      overflow: auto !important;
    }
  }
}

.listItems {
  padding-bottom: rem(16px);
  padding-left: 16px;
}
