@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {

}

.tabContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  :global {
    .chakra-tabs__tab-panels {
      height: 100%;
      overflow: auto;
      padding-bottom: 2rem;
    }
    .chakra-tabs__tablist {
      border-bottom: 1px solid var(--alpha-postLine);
      .chakra-tabs__tab {
        font-size: rem(14);
        color: var(--alpha-tablist);
        padding: rem(8);
        margin-bottom: -1px;
        &[aria-selected='true'],
        &:focus-visible,
        &:active {
          color: var(--alpha-text);
          border-bottom: 1px solid var(--alpha-text);
        }
      }
    }

    .chakra-tabs__tab-panel {
      // padding: 0;
      // margin-top: 24px;
    }
  }
}

.forgingContainer {
  position: relative;
  align-items: center;
  svg {
    max-width: 90px;
  }
  .forgingTitle {
    /* Community-driven crypto keys */

    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
  }
  .forgingDesc {
    /* Forge keys together with the community and win together. */

    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    /* or 18px */

    opacity: 0.8;
    margin-top: 6px;
  }
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    svg {
      font-size: 10px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.7;
    }
  }
}