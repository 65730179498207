@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.cashOut {
  color: var(--alpha-text);
}

.referents {
  overflow: hidden;

  .table_heading {
    display: flex;
    padding-bottom: 12px;
    border-bottom: 1px solid #5b5b5b;

    .table_heading__col {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: var(--alpha-text);
      opacity: 0.8;
      flex: 0 25%;
      white-space: nowrap;

      &:nth-child(1) {
        flex: 0 25%;
        text-align: left;
      }

      &:nth-child(4) {
        flex: 0 25%;
        text-align: right;
      }
    }
  }

  .table_body {
    position: relative;
    min-height: 300px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    gap: 10px;

    .loading {
      min-height: inherit;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      top: 0;
      left: 0;
      background-color: #ffffff10;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.modalContent {
  box-shadow: 0px 0px 1px 1px #ffffff22 !important;

  &:global(.chakra-modal__content) {
    background-color: #000 !important;
    min-width: 90% !important;
  }

  &:global(.chakra-modal__content) {
    background-color: #000 !important;
    min-width: 90% !important;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.col {
  font-size: 10px;
  padding: 10px 0;
  color: white;
  width: 25%;
}

.head {
  .col {
    color: #898989;
    // &:first-child {
    //   width: 25%;
    // }
    // &:last-child {
    //   width: 25%;
    // }
    &.end {
      text-align: right;
    }
  }
}

.body {
  height: 400px;
  overflow: hidden;

  > div {
    overflow: hidden;
  }

  .col {
    font-weight: 600;
    white-space: nowrap;

    &.txCol {
      .flex {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .hash {
        width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &:last-child {
      font-size: 12px;
    }
    &.end {
      text-align: right;
    }
    &.pending {
      color: #f9d03f;
    }
    &.success {
      color: #00aa6c;
    }
    &.timeout {
      color: #ff4747;
    }
  }
}
