@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.button {
  display: flex;
  flex-direction: row;
  justify-content: left !important;
  gap: 24px;
  color: black !important;
  font-size: 16px !important;
  padding-left: 16px !important;
  width: 100%;
  img {
    width: 28px;
  }
}
