@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.formContainer {
  width: 100%;
  background: var(--alpha-headerBg);

  border-radius: 0;
  position: relative;

  .messageErrorText {
    color: #ff6868 !important;
    font-weight: 500;
    font-size: rem(12);
  }

  .backContainer {
    // position: absolute;
    // left: rem(20);
    height: 45px;
    width: 30px;
    border-radius: 8px;
    padding: 0;
    background-color: transparent;
    //background-color: var((--alpha-backContainerBg));

    svg {
      stroke: var(--alpha-text);
    }
  }

  .btnProContainer {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    height: 45px;
    width: 73px;
    padding: 0;

    background: var(--alpha-btnProBg);
    border: 1px solid var(--alpha-btnProBorder);
    box-shadow: 0px 0px 12px -6px rgba(116, 200, 247, 0.16);
    border-radius: 8px;

    /* identical to box height, or 186% */
    display: flex;
    align-items: center;
    text-align: center;

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      text-transform: uppercase;
      color: var(--alpha-btnProText);
    }

    svg {
      path {
        fill: var(--alpha-btnProText);
      }
    }
  }

  .formTitle {
    font-weight: 500;
    font-size: rem(20);
    color: var(--alpha-text);
  }

  .formDesc {
    font-weight: 400;
    font-size: rem(14);
    line-height: 150%;
    color: var(--alpha-text);
    text-align: center;
    letter-spacing: -0.5px;
    opacity: 0.6;
  }
}

.formWrapper {
  width: 100%;

  .twitterName {
    font-family: $font-alp;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    /* identical to box height, or 20px */

    color: #ffffff;
  }

  .twitterUserName {
    font-family: $font-alp;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 14px */

    color: #ffffff;

    opacity: 0.7;
  }

  .priceBTC {
    font-family: $font-alp;
    /* 0.32523 BTC */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    /* identical to box height, or 20px */

    color: #f8f0ac !important;
  }

  .usdBTC {
    font-family: $font-alp;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 14px */

    color: #ffffff;

    opacity: 0.7;
  }

  :global {
    .chakra-text {
      color: var(--alpha-text);
    }
  }

  .btnSubmit {
    background: var(--alpha-tradeSubmit) !important;
    color: var(--alpha-btn-text);
    height: 44px;
    border-radius: 12px;
    // font-size: rem(16);
    font-weight: 500;
    width: 100%;
  }
}

.formPrice {
  color: var(--alpha-btn-bg) !important;
}

.bySubmitOrder {
  color: var(--alpha-formNoti) !important;

  b {
    color: var(--alpha-btn-bg) !important;
  }
}

.inputAmountWrap {
  :global .chakra-input__left-element {
    margin: 0;
    width: unset;
    border-right: 1px solid #5b5b5b;
  }

  :global .chakra-input__right-element {
    padding-right: 16px;
    margin: 0;
  }

  :global .chakra-form__error-message {
    font-size: 12px;
  }

  :global .chakra-input__group {
    background: var(--alpha-codeBlockBg);
    align-items: center;
  }

  p {
    font-size: 1.6rem;
    font-weight: 500;
  }
}

.inputAmount {
  font-size: 16px !important;
  height: 48px !important;
  font-weight: 500 !important;

  input {
    font-size: 16px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.modalConfirm {
  :global {
    .modal-header-wrap {
      margin-bottom: 0;
    }

    .chakra-modal__body {
      padding: rem(24);
    }
  }

  .btnConfirm {
    background: #1da1f2;
    color: #ffffff;
    height: 44px;
    border-radius: 12px;
    font-size: rem(14);
    font-weight: 500;
  }
}

.note {
  font-size: 13px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}

.confirmModal {
  background: var(--alpha-confirmModalBg) !important;

  :global {
    .chakra-modal__header {
      font-size: rem(26);
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .btnConfirm {
    width: 100%;
    border-radius: 8px;
    // margin-top: 20px;
    margin-bottom: 10px;
    font-size: rem(18);
    min-height: 50px;
    color: var(--alpha-btn-text);
  }

  .confirmLabel {
    color: var(--alpha-notiChakra);
  }
}

.infoKeyContainer {
  border: 1px solid var(--alpha-infoKeyContainerBorder);
  border-radius: 8px;
  padding: 15px 10px;
  background: var(--alpha-infoKeyContainerBg);
}

.btnMax {
  width: 50px;
  height: 30px !important;
  padding: 0 !important;
  font-size: rem(16) !important;
  border-radius: 4px !important;
  color: var(--alpha-chakraBtnText) !important;
  background-color: var(--alpha-chakraBtnBg) !important;
}

.proTradeContainer {
  position: relative !important;
  border-radius: 0;
  // max-height: calc(100% - 73px - 40px);
  // height: calc(100% - 73px - 40px);
  // height: 100%;
  height: 100%;
  overflow: hidden;
  margin-top: 0;
  padding: 0px !important;

  .header {
    width: 100%;
    align-items: center;
    padding: 10px 12px;
    border-bottom: 1px solid #ffffff26;
    position: relative;
    height: 52px;
    min-height: 52px;

    // transition: all 1s;

    .btnSearch {
      width: 40px;
      height: 40px;
      border-radius: 0;
      background-color: transparent;
      &.active {
        svg {
          path {
            fill: #ffffff;
            opacity: 1;
          }
        }
      }
    }

    :global {
      .chakra-popover__popper {
        position: absolute;
        top: 0 !important;
        left: 0 !important;
        transform: unset !important;
        width: 100%;
        .chakra-popover__content {
          width: 100%;
          border: none !important;
        }
      }
    }

    .inputContainer {
      height: 40px;
      align-items: center;
      display: flex;
      gap: 8px;
      border: none;
      border-radius: 8px;
      width: 100%;

      &:focus-within {
        position: absolute;
        // width: calc(100% - 20px);
        background-color: #212121;
        z-index: 9;
        left: 10px;

        :global {
          .chakra-input__right-element {
            // display: flex;
            z-index: 9;
            // display: unset;
            opacity: 1;
            right: 10px;
          }
        }
      }

      :global {
        .chakra-input__left-element {
          // width: 40px;
          height: 40px;

          svg {
            path {
              fill: var(--alpha-iconSvg);
            }
          }
        }

        .chakra-input__right-element {
          width: 40px;
          height: 40px;
        }
      }

      input {
        border-radius: 8px;
        height: 40px;
        padding-inline-start: 35px;
        padding-inline-end: 0;
        border: none !important;
        outline: 0;
        background-color: transparent;
        // background-color: var(--alpha-warningBlockBg);

        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: var(--alpha-text);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:focus {
          // padding-inline-end: 40px;
        }

        &:focus-visible {
          box-shadow: none;
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }

    .iconButton {
      background-color: transparent;
      width: 40px;
      height: 100%;

      svg {
        height: 24px;

        path {
          opacity: 0.5;
        }
      }

      &.active {
        svg {
          path {
            // fill: #ff6868;
            opacity: 1;
          }
        }
      }
    }
  }

  .footer {
    width: 100%;
    min-height: 60px;
    height: 60px;
    background: var(--alpha-navBg);
    box-shadow: 0px 0px 24px -6px rgba(0, 0, 0, 0.12);
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    gap: 12px;

    .label {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: var(--alpha-text);
    }

    .btnAction {
      height: 40px !important;
      width: 100%;
      // width: 68px !important;
      padding: 1rem 2rem !important;
      //max-width: 70px;
    }

    .btnBuy {
      &.isDisabled {
        background-color: #f8f0ac;
        opacity: 1;
      }

      &.isNotAllowBuy {
        background-color: #f8f0ac;
        padding-top: 18px;
        padding-bottom: 18px;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: 0.01em;

        /* Color-newsystem/White/100 */
        color: var(--alpha-btn-text) !important;
      }
    }

    .btnInvite {
      background-color: #a1e3cb !important;

      &.isDisabledInvite {
        background-color: #a1e3cb !important;
        opacity: 1;
      }

      &.isNotAllowInvite {
        background-color: #a1e3cb !important;
        padding-top: 18px;
        padding-bottom: 18px;
      }

      p {
        font-weight: 500;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.01em;

        /* Color-newsystem/White/100 */
        color: var(--alpha-btn-text) !important;
      }
    }

    .errorText {
      font-size: rem(11);
      text-align: center;
      margin-top: 1px;
      color: #ff6868;
      font-weight: 500;
    }
  }

  :global {
    .chakra-table__container {
      // height: 100%;
      overflow: hidden;
      // padding: 0 12px;
      width: 100%;

      .chakra-table {
        position: relative;
        table-layout: fixed;

        thead,
        tbody {
          tr {
            th,
            td {
              &:first-child {
                padding-right: 8px;
              }
              &:last-child {
                width: 14%;
                button {
                  padding-left: 0;
                  padding-right: 0;
                }
              }
              &:nth-child(2) {
                width: 25%;
              }
            }
          }
        }

        thead {
          tr {
            //check this
            th {
              padding: 12px 0px;
              border-bottom: 1px solid var(--alpha-postLine);
              background-color: var(--alpha-bg);
              position: sticky;
              top: 0;
              z-index: 1;

              &:first-child {
                padding-left: 12px;
              }

              &:last-child {
                padding-left: 8px;
                padding-right: 12px;

                > div {
                  justify-content: flex-end;
                }
              }

              @media screen and (max-width: 768px) {
                &:nth-last-child(2) {
                  > div {
                    justify-content: flex-end;
                  }
                }
              }
            }
          }
        }

        tbody {
          td {
            padding: 16px 0px;
            //border-color: transparent;
            //color: #FFFFFF;
            //font-family: var(--bebas-neue);
            //font-size: 20px;
            vertical-align: middle;
            //border-bottom: 1px solid #ECECEC;

            &:first-child {
              padding-left: 12px;
            }

            &:last-child {
              padding-left: 8px;
              padding-right: 12px;
            }
          }
        }
      }
    }

    .infinite-scroll-component__outerdiv {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .infinite-scroll-component {
        // overflow: auto;
        // height: 100%;
        // height: 100% !important;
      }
    }
  }
}

.keysList {
  // padding: 12px;
}

.follower {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  text-align: right;
  letter-spacing: -0.03em;

  color: var(--alpha-text);
}

.buyer {
  color: var(--alpha-text) !important;
  line-height: 20px;
  max-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.price {
  //font-size: rem(16);
  //font-weight: 500;
  color: var(--alpha-text) !important;
}

.checkboxContainer {
  width: 14px;
  min-width: 14px;
  height: 14px;
}

.filterTitle {
  font-size: 14px !important;
  color: var(--alpha-text) !important;
  font-family: $font-alp;
  margin-bottom: 5px;

  b {
    font-size: 14px;
    font-weight: 500;
  }
}

.buyingContainer {
  .buyingContainerItem {
    align-items: center;
  }
  .buyingContainerItemLast {
    padding-bottom: 20px;
  }
}

.avatarPreSale {
  position: relative;

  > div {
    img {
      filter: blur(0.8px);
    }
  }

  .imgPreSale {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.preSaleWrapTitle {
  background-color: var(--alpha-infoKeyContainerBorder);
  align-self: flex-start;
  padding: 0 10px;
  border-radius: 4px;

  p {
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    color: #5b5b5b;
  }
}

.keyContainer {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  align-items: center;
  justify-content: center;

  background: var(--alpha-infoKeyContainerBg);
  box-shadow: 0 0 8px -4px #000;
  border-radius: 100%;

  &.keyContainerPresale {
    // background-color: #ececed;
  }

  svg {
    width: 12px;
    height: 12px;
  }
}

.filterModal {
  background: var(--alpha-bg) !important;
  color: var(--alpha-text) !important;

  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);

  :global {
    .chakra-modal__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }
  }

  .rowFilter {
    margin-bottom: 10px;

    .title {
      font-size: rem(16);
      font-weight: 500;
      opacity: 0.5;
      font-family: $font-alp;
      color: var(--alpha-text);
    }
  }

  :global {
    .chakra-modal__header {
      font-size: rem(20);

      svg {
        path {
          stroke: var(--alpha-iconSvg);
        }
      }
    }

    .chakra-modal__footer {
      button {
        font-size: rem(16);
        font-family: $font-alp !important;
        padding: 0;
        height: 40px;
        color: var(--alpha-btn-text);
      }
    }
  }

  .btnReset {
    background-color: #eaeaea;
    color: #949494;
    width: 50%;
  }

  .btnApply {
    width: 100%;
  }
}

.showNumFilters {
  background-color: #ff0000;
  position: absolute;
  bottom: 3px;
  left: 20px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.showSearchActive {
    bottom: 10px;
    left: 22px;
  }

  p {
    color: white;
    font-size: rem(10);
    font-weight: 500;
    line-height: 14px;
  }
}

.itemSort {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  justify-content: center;

  p {
    font-size: rem(14);
    font-family: $font-alp;
    color: var(--alpha-btn-text) !important;
  }

  &.itemSortActive {
    background-color: var(--alpha-activeFilter);

    p {
      color: var(--alpha-btn-text);
    }
  }
}

.modalContent {
  :global {
    padding: 20px !important;

    .modal-header-wrap {
      z-index: 1;
      margin-bottom: 16px;
      //display: none;
    }
    .chakra-modal__header {
      font-size: 18px !important;
      font-width: 600 !important;
      text-transform: capitalize !important;
      margin-bottom: 16px;
      display: none;
    }

    //.chakra-modal__close-btn {
    //  display: none;
    //}
  }
}

.sortByButton {
  background-color: transparent !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  padding: 0 !important;
  width: max-content !important;
  min-width: max-content !important;
  & > span {
    display: flex;
    gap: 2px;
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      opacity: 0.6;

      color: #ffffff !important;
    }
    b {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* Color-newsystem/White/100 */
      color: #ffffff !important;
    }
    svg {
      color: white;
    }
  }

  :global {
    .chakra-button__icon {
      margin-inline-start: 0;
    }
  }
}

.sortByList {
  z-index: 9 !important;
  // background-color: transparent !important;
  // background: transparent !important;
  :global {
    .chakra-menu__menu-list {
      padding: 6px !important;
      box-shadow: 0px 0px 24px -6px rgba(0, 0, 0, 0.12);
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 12px;
      .chakra-menu__menuitem {
        border-radius: 8px;
        background: linear-gradient(0deg, #292929, #292929),
          linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
          );
        padding: 6px 12px;
        border: 0px;
        border-color: transparent;

        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        /* identical to box height, or 186% */
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        border-width: 0px;
        outline: 0;
        outline-offset: 0;

        &.active {
          // color: #ff6868;
          // background: #ff6868;
        }
      }
    }
  }
}

.sortByTextContainer {
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    opacity: 0.6;
    &.active {
      opacity: 1;
    }
  }
}

.wrapModel {
  .filterText {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    opacity: 0.7;
    /* identical to box height, or 143% */
    content: '\e8d6';
  }
}

.checkbox {
  text-align: center;

  :global(.chakra-checkbox__control) {
    width: 16px;
    height: 16px;
    border-radius: 4px;

    &[data-checked] {
      background: #f8f0ac;
      border-color: #f8f0ac;

      svg,
      path {
        stroke: #000 !important;
      }
    }
  }
}
