@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.estimatePrizeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: var(--alpha-referAdnEarnBg) !important;
  background-color: rgba($color: white, $alpha: 0.15);
  border-radius: 12px;
  margin: 16px 0px;
  padding: 12px;

  .estimatePrizeWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .estimatePrizeTitleWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  p {
    color: rgba($color: white, $alpha: 0.7);
    font-size: 12px;
  }

  img {
    width: 32px;
    height: 32px;
    margin-bottom: 3px;
  }
}

.content {
  padding: 0px 24px;
}

.jackpotWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;

  // &:last-child {
  //   margin-bottom: 0;
  // }

  .valueText {
    color: #f8f0ac;
    font-family: var(--font-heading);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    display: flex;
    align-items: center;
  }

  .deadline {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
  }
}

.gameInfo {
  position: relative;

  .gameThumbnail {
    object-fit: cover;
    // border-radius: 16px;
    aspect-ratio: 2;
    // border: 1px solid #505050;
  }

  .infoButton {
    width: 76px;
    height: 32px;
    border-radius: 8px;
    font-weight: 600;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    margin-left: 6px;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #f8f0ac;
    font-size: 12px;
    font-weight: 600;
    color: #000;

    svg {
      margin-right: 4px;
    }
  }
}

.playButton {
  height: 56px;
  flex-direction: row;
  display: flex;
  border-radius: 56px;
  align-items: center;
  justify-content: center;
  background: var(--alpha-pointNumber) !important;
  width: 100%;
  margin-top: 6px;

  p {
    color: #000;
    font-size: 18px;
    font-weight: 700;
  }

  span {
    color: #000 !important;
    font-size: 14px;
    margin-top: 2px;
    margin-left: 2px;
    display: flex;
    align-items: center;
  }
}

.topUserProfileWrapper {
  align-items: center;
  display: flex;
  background-color: #000;
  border-radius: 12px;
  padding: 9px 16px 9px 12px;
  width: 100%;
  margin-top: 24px;

  .rankText {
    color: #30ff9b;
    font-size: 14px;
    font-weight: 700;
    margin-right: 12px;
  }
}

.leaderBoardButtonWrapper {
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: white, $alpha: 0.1);
  border-radius: 8px;
  padding: 9px 16px 9px 12px;
  width: 100%;
  margin-top: 8px;

  svg {
    margin-right: 4px;
  }
}

.name {
  font-size: 14px !important;
  margin-left: 10px;
  margin-right: 5px;
  color: white !important;
  font-weight: 500 !important;
}

.userName {
  margin-left: 10px;
  margin-right: 5px;
  color: rgba($color: white, $alpha: 0.7);
}

.userScore {
  flex: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 4px;

  .title {
    font-size: 10px;
    color: #30ff9b;
  }

  .scoreValue {
    color: #30ff9b;
    font-size: 16px;
    font-weight: 600;
  }
}

.yourUserProfileWrapper {
  .yourUserProfile {
    margin-bottom: 16px;
    font-size: small;
    background-color: rgba($color: white, $alpha: 0.15);
    border-radius: 12px;
    align-items: center;
    display: flex;
    flex-direction: column;

    .header {
      align-items: center;
      display: flex;
      border-radius: 12px;
      padding: 12px 16px 0px 12px;
      width: 100%;
    }

    .userRowRewardWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 12px;

      .userRewardWrapper {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;
        background-color: rgba($color: #4ed695, $alpha: 0.5);

        &:last-child {
          background-color: rgba($color: #1c9d5f, $alpha: 0.5);
          border-radius: 0px 0px 12px 12px;
        }

        .valueText {
          font-size: 16px;
          color: #4ed695;
          font-weight: 600;
        }
      }

      .target {
        font-size: 12px;
        margin-left: 16px;
      }
    }
  }
}

.blockPopup {
  font-size: 16px;
  color: #fff;
  text-align: center;

  .smallText {
    font-size: 14px;
    color: #fff;
  }
}

.customSvg {
  display: flex;
  justify-content: center;

  svg {
    width: 80px;
    height: 80px;
  }
}

.balance {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  justify-content: end !important;
  text-align: end !important;
}