@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;400;500&display=swap');

@font-face {
  font-family: 'Bandeins Strange Variable';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url(/fonts/BandeinsSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Code Pro';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url(/fonts/SourceCodePro-Regular.woff2) format('woff2');
}

@font-face {
  font-family: EastSeaDokdo;
  font-weight: 400;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url(/fonts/EastSeaDokdo-Regular.ttf);
}

@font-face {
  font-family: 'Bandeins Strange Variable';
  src: url('/fonts/BandeinsStrange-Light.eot');
  src: local('/fonts/Bandeins Strange Variable'),
    local('BandeinsStrangeVariable'),
    url('/fonts/BandeinsStrange-Light.woff2') format('woff2'),
    url('/fonts/BandeinsStrange-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bandeins Strange Variable';
  src: url('/fonts/BandeinsStrange-Regular.eot');
  src: local('/fonts/Bandeins Strange Variable'),
    local('BandeinsStrangeVariable'),
    url('/fonts/BandeinsStrange-Regular.woff2') format('woff2'),
    url('/fonts/BandeinsStrange-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bandeins Strange Variable';
  src: url('/fonts/BandeinsStrange-Medium.eot');
  src: local('/fonts/Bandeins Strange Variable'),
    local('BandeinsStrangeVariable'),
    url('/fonts/BandeinsStrange-Medium.woff2') format('woff2'),
    url('/fonts/BandeinsStrange-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bandeins Strange Variable';
  src: url('/fonts/BandeinsStrange-SemiBold.eot');
  src: local('/fonts/Bandeins Strange Variable'),
    local('BandeinsStrangeVariable'),
    url('/fonts/BandeinsStrange-SemiBold.woff2') format('woff2'),
    url('/fonts/BandeinsStrange-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bandeins Strange Variable';
  src: url('/fonts/BandeinsStrange-Bold.eot');
  src: local('/fonts/Bandeins Strange Variable'),
    local('BandeinsStrangeVariable'),
    url('/fonts/BandeinsStrange-Bold.woff2') format('woff2'),
    url('/fonts/BandeinsStrange-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: BandeinsSans;
  src: local('/fonts/BandeinsSans'), local('BandeinsSans'),
    url('/fonts/BandeinsSans-Bold.woff2') format('woff2'),
    url('/fonts/BandeinsSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: BandeinsSans;
  src: local('/fonts/BandeinsSans'), local('BandeinsSans'),
    url('/fonts/BandeinsSans-Regular.woff2') format('woff2'),
    url('/fonts/BandeinsSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: BandeinsSans;
  src: local('/fonts/BandeinsSans'), local('BandeinsSans'),
    url('/fonts/BandeinsSans-Medium.woff2') format('woff2'),
    url('/fonts/BandeinsSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: BandeinsSans;
  src: local('/fonts/BandeinsSans'), local('BandeinsSans'),
    url('/fonts/BandeinsSans-Light.woff2') format('woff2'),
    url('/fonts/BandeinsSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: BandeinsSans;
  src: local('/fonts/BandeinsSans'), local('BandeinsSans'),
    url('/fonts/BandeinsSans-SemiBold.woff2') format('woff2'),
    url('/fonts/BandeinsSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wulkan Display';
  src: local('/fonts/WulkanDisplay'), local('WulkanDisplay'),
    url('/fonts/WulkanDisplayMedium.woff2') format('woff2'),
    url('/fonts/WulkanDisplayMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
