@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.loadingWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gameScreen {
  height: 100%;
  overflow: auto;
  background-color: #000;
}

.heading {
  color: #fff;
  font-size: 12px;
  line-height: 150%;
  margin-top: 24px;
  margin-left: 12px;
  margin-bottom: 12px;
  text-align: center;
  font-style: italic;

  * {
    color: #fff;
  }
}

.gameList {
  padding-bottom: 16px;
}

.gameItem {
  padding: 12px;
  display: flex;
  gap: 24px;
  align-items: center;
  border-radius: 12px;
  background: var(--alpha-referAdnEarnBg) !important;
  box-shadow: 0px 0px 24px -6px rgba(0, 0, 0, 0.12);

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.gameInfoWrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.gameInfoContent {
  display: flex;
  gap: 12px;
  align-items: center;
}

.gameInfoContent {
  display: flex;
}

.gameInfo {
  // padding-right: 10px;
}

.thumbnailImg {
  min-width: 110px;
  width: 110px;
  height: 86px;
  height: 86px;
  object-fit: cover;
  border-radius: 8px;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  animation: 1.5s shine linear infinite;
  background-size: 200% 100%;
}

.rankingBadge {
  width: 32px;
  height: 32px;
  flex-basis: 32px;
}

.name {
  color: #fff;
  font-family: var(--font-heading);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 22.4px */
  margin-bottom: 2px;
}

.desc {
  color: #fff;
  opacity: 0.5;
  font-family: var(--font-heading);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  /* 22.4px */
}

.match {
  color: #000;
  font-family: var(--font-body);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  /* 120% */
  display: flex;
  align-items: center;
  gap: 4px;
}

.practiceBtn {
  border-radius: 100px;
  background: var(--alpha-pointNumber) !important;
  color: #000;
  text-align: right;
  font-family: var(--font-body);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 19.2px */
  padding: 4px 8px;
}

.practiceBtnSecondary {
  border-radius: 100px;
  background: var(--alpha-pointNumber) !important;
  color: #000;
  text-align: right;
  font-family: var(--font-body);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 19.2px */
  padding: 10px 18px;
}

.ranking {
  color: #000;
  display: inline-block;
  width: 32px;
  text-align: center;
  font-family: var(--font-body);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 22.4px */
  flex-basis: 32px;
}

.gameWrapper {
  padding: 0px 6px;
  background-color: #000;
}

.backAction {
  flex: 0;
  height: 44px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    path {
      stroke: var(--alpha-backAction);
    }
  }
}