@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.wrapper {
  height: 84svh;
  display: flex;
  flex-direction: column;

  iframe {
    margin-top: 12px;
    margin-bottom: 12px;
    height: 100%;
    flex: 1;
  }

  .practiceBtn {
    padding: 12px 0;
    margin: 0 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.14px;
    border-radius: 100px;
    background: var(--alpha-pointNumber) !important;
    color: #000;
    margin-bottom: 12px;
  }

}
