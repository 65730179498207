@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.name_wrapper {
  width: 100px;
  text-align: center;
}

.name {
  display: block;
  color: #fff;
  font-weight: 600;
  font-family: $font-alp;
  font-size: 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // @include text-ellipsis;
}

.status {
  font-family: $font-alp;
  font-size: 12px;
  opacity: 0.7;
}

.wrapper {
  position: relative;
}

.avatar_wrapper {
  display: grid;
  place-items: center;
  width: 100%;
}

.avatar {
  position: relative;
  width: 52px;
  height: 52px;
  max-height: 107px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.speaking {}

.user_type {
  .name {
    color: #FFF;
    font-size: 10px;
    line-height: 140%;
    /* 14px */
    opacity: 0.7;
  }
}

.speaker {
  background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
}

.audience {
  background: transparent;
}

.mic_on {
  position: absolute;
  width: 18px;
  height: 18px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);

  svg,
  path {
    fill: #fff;
  }
}

.mic_off {
  position: absolute;
  width: 18px;
  height: 18px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.avatar {
  position: relative;

  &_gif {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #2E2E2E;
    border: 1px solid #898989;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
  }

  svg {
    width: 12px;
    height: 12px;
  }
}