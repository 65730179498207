@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.imgWrapper {
  display: flex;
  justify-content: center;
}

.icYolo {
  max-width: 70px;
  margin-bottom: 16px;
}

.title {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: $font-alp;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  white-space: nowrap;
}

.description {
  color: #ffebc8 !important;
  text-align: center;
  font-family: $font-alp;
  margin-bottom: 12px;
  font-size: 68px;
  font-weight: 600;
  line-height: 120%; /* 81.6px */
}

.modalWrapper {
  display: flex;
  justify-content: center;
}

.success_wrapper {
  position: relative;
  max-width: 264px;

  .background {
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    width: 100%;
    padding: 0 16px;
    position: absolute;
    bottom: 28px;
    z-index: 1;
  }
}

.openBtn {
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 40px;
  background: #f8f0ac;
  color: #000;
  text-align: center;
  font-family: $font-alp;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 185.714% */
  letter-spacing: 0.14px;

  &:disabled {
    opacity: 0.6;
  }
}
