@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.149);
  background: var(--alpha-bg);
  width: rem(1229);
  margin: 0 auto;
  border-radius: 20px;
  // flex: 1;
  padding: rem(20);
  padding-right: 0;
  overflow: hidden;
  height: calc(100svh - 132px);

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: rem(0);
    border-radius: 8px;
  }
}

.tabContainer {
  width: 100%;
  height: calc(100svh - 132px);
  overflow: hidden;

  :global {
    .chakra-table__container {
      .chakra-table {
        table-layout: fixed;

        thead {
          tr {
            th {
              &:first-child {
                padding-left: 12px;
              }

              &:last-child {
                padding-right: 12px;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              &:first-child {
                padding-left: 12px;
              }

              &:last-child {
                padding-right: 12px;
              }
            }
          }
        }
      }
    }

    .chakra-tabs__tablist {
      overflow-x: auto;
      overflow-y: hidden;
      button {
        width: max-content;
        min-width: fit-content;
      }
    }
  }

  :global {
    .chakra-tabs__tab-panels {
      height: calc(100% - 41px);
      overflow: hidden;
    }
    .chakra-tabs__tablist {
      .chakra-tabs__tab {
        margin-bottom: 0px;
      }
    }

    .chakra-tabs__tab-panel {
      height: 100%;
      // overflow: hidden;
    }
  }
}

.btnBack {
  margin-left: -10px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
