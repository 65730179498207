:root {
  --alpha-bg: #ffffff;
  --alpha-navBg: #ffffff;
  --alpha-headerBg: #ffffff;
  --alpha-stepItem-bg: #ffffff;
  --alpha-btn-text: #ffffff;
  --alpha-iconWrapper: #ffffff;
  --alpha-topUpSvg: #ffffff;
  --alpha-profileBtn: #ffffff;
  --alpha-tabListBg: #ffffff;
  --alpha-feedItem: #ffffff;
  --alpha-wrapper: #ffffff;
  --alpha-walletBox: #ffffff;
  --alpha-walletInfo: #ffffff;
  --alpha-walletBlockBg: #ffffff;
  --alpha-listMenu: #ffffff;
  --alpha-pointContentBg: #ffffff;
  --alpha-referAdnEarnBg: #ffffff;
  --alpha-form: #ffffff;
  --alpha-tipsBtnSvg: #ffffff;
  --alpha-confirmBg: #ffffff;
  --alpha-backBtnBg: #ffffff;
  --alpha-slideThumbBorder: #ffffff;
  --alpha-checkBoxIcon: #ffffff;
  --alpha-btnProBg: #ffffff;
  --alpha-confirmModalBg: #fff;
  --alpha-redDotBorder: #fff;
  --alpha-redDotBorderDM: #fff;
  //#ffffff

  --alpha-text: #000000;
  --alpha-icon: #000000;
  --alpha-createBtnBg: #000000;
  --alpha-svgPath: #000000;
  --alpha-twId: #000000;
  --alpha-backAction: #000000;
  --alpha-num: #000000;
  --alpha-confirmText: #000000;
  --alpha-btnProText: #000000;
  --alpha-chakraContent: #000000;
  --alpha-avatar-border: #ffffff;
  //#000000

  --alpha-navBorder: #ececec;
  --alpha-headerBorder: #ececec;
  --alpha-profileBtnBorder: #ececec;
  --alpha-tabListBorder: #ececec;
  --alpha-feedItemBorder: #ececec;
  --alpha-feedItemBorderSpec: #ececec;
  --alpha-warningBlockBorder: #ececec;
  --alpha-wrapperBorder: #ececec;
  --alpha-postItemBorder: #ececec;
  --alpha-divider: #ececec;
  --alpha-walletBorder: #ececec;
  --alpha-menuListBorder: #ececec;
  --alpha-postLine: #ececec;
  //#ececec

  --alpha-btn-bg: #4185ec;
  --alpha-hastag: #4185ec;
  --alpha-feedItemName: #4185ec;
  --alpha-replyName: #4185ec;
  --alpha-walletBtnText: #4185ec;
  --alpha-walletBtnIcon: #4185ec;
  --alpha-uploadMediaIcon: #4185ec;
  //#4185ec

  --alpha-feedItemPrice: #00aa6c;
  --alpha-feedItemSvg: #00aa6c;
  //#00aa6c

  --alpha-topUpBtn: #35d680;
  --alpha-referAdnEarnBorder: #35d680;
  --alpha-referAdnEarnText: #35d680;
  --alpha-pointNumber: #35d680;
  //#35d680

  --alpha-btnProBorder: rgba(116, 200, 246, 0.4);
  --alpha-pointContentBorder: rgba(116, 200, 246, 0.4);
  --alpha-walletBlockBorder: rgba(116, 200, 246, 0.4);
  --alpha-stepItem-border: rgba(116, 200, 246, 0.4);
  //rgba(116, 200, 246, 0.4)

  --alpha-warningBlockBg: #f6f6f6;
  --alpha-walletBg: #f6f6f6;
  --alpha-unReportBtnBg: #f6f6f6;
  --alpha-notifyBgText: #f6f6f6;
  //#f6f6f6

  --alpha-menuListInputColor: #5b5b5b;
  --alpha-chakraInput: #5b5b5b;
  --alpha-tipsDesc: #5b5b5b;
  --alpha-chakraBtnText: #5b5b5b;
  //#5b5b5b

  --alpha-uploadImgBg: transparent;
  --alpha-codeBg: transparent;
  --alpha-infoKeyContainerBg: transparent;
  --alpha-codeBlockBg: transparent;
  --alpha-menuListInputBg: transparent;
  //transparent

  --alpha-createPostBtn: rgba(65, 133, 236);
  --alpha-postBtnBg: rgb(65, 133, 236);

  --alpha-safeGuard: #ff7e21;
  --alpha-walletBtn: rgba(65, 133, 236, 0.1);
  --alpha-chakraBox: #cbc7c7;
  --alpha-walletSubmitBtn: #264f9d;
  --alpha-pointContentShadow: rgb(116, 200, 246);
  --alpha-menuListInputBorder: #cecece;
  --alpha-tradeBg: #fafafa;
  --alpha-codeBlockBorder: #74c8f666;
  --alpha-walletEarn: #4185ec;
  --alpha-infoKeyContainerBorder: #ececed;
  --alpha-tcText: #1c1c1c;
  --alpha-tradeSubmit: #1da1f2;
  --alpha-backContainerBg: #f5f5f5;
  --alpha-tipsWrapperBg: #f4f4f4;
  --alpha-tipsBtn: #1588ff;
  --alpha-confirmBorder: rgba(236, 236, 236, 0.93);
  --alpha-subTitle: rgba(0, 0, 0, 0.7);
  --alpha-chakraBtnBg: #eaeaea;
  --alpha-notiChakra: rgba(0, 0, 0, 0.5);
  --alpha-checkBox: #3182ce;
  --alpha-bgFlashing50: rgba(65, 133, 236, 0.05);
  --alpha-bgFlashing0: rgba(65, 133, 236, 0.3);
  --alpha-imgBorder: #212121;
  --alpha-uploadImgIcon: #4c9eeb;
  --alpha-uploadImgStroke: #74c8f6;
  --alpha-blockWrapperShadow: rgba(116, 200, 246, 0.16);
  --alpha-dataPreviewBorder: #d1d5db;
  --alpha-formDesc: #5b5b5b;
  --alpha-formBoxBg: #f4f4f4;
  --alpha-formNoti: #1e1e1e;
  --alpha-notifyBg: #4c9eeb;
  --alpha-iconSvg: #898989;
  --alpha-activeFilter: #4c9eeb;
  --alpha-createModalBg: rgb(219, 242, 255);
  --alpha-createModalText: rgb(76, 158, 235);
  --alpha-bottomBar: #bdc5cd;
  --alpha-updateGifShadow: #dfdfdf;
  --alpha-secureWalletBorder: #74c8f629;
  --alpha-placeholderInputChat: rgba(0, 0, 0, 0.6);
  --alpha-jackpotBtnBg: #264f9d;
  --alpha-jackpotBtnDBg: #264f9d26;
  --alpha-earnBtn: #00db51;
  --alpha-tablist: rgba(0, 0, 0, 0.6);
  --alpha-growUp: linear-gradient(180deg, #a3ffd3 0%, #4cfea6 100%);
  --alpha-growDown: linear-gradient(180deg, #ffb3b2 0%, #ff7f7e 100%);
  --alpha-itemActive: transparent;
  --alpha-receiverBg: #eeeeee;
  --alpha-receiverText: #505050;
  --alpha-directionPost: #687684;
  --alpha-itemKeyBorder: rgba(116, 200, 246, 0.15);
  --alpha-switchHolding: #f6f6f6;
  --alpha-welcomeLink: #264f9d;
  --alpha-welcomeLinkBg: transparent;
  --alpha-welcomeLinkBorder: rgba(116, 200, 246, 0.2);
  --alpha-welcomeLinkShadow: rgba(116, 200, 246, 0.12);

  // DM
  --alpha-dm-background: #2d2d2d;
  --alpha-dm-border: #212020;
  --alpha-dm-primary-text-color: #fff;
  --alpha-dm-secondary-text-color: #000;
  --alpha-dm-unread-color: #f8f0ac;
  --alpha-dm-unread-text-color: #8c6d00;
  --alpha-dm-online-background: #2cc069;
  --alpha-dm-online-border: #fff;

  --alpha-dm-link-color: #3369ff;
  --alpha-dm-message-sender-background: #ffbc8c;
  --alpha-dm-message-receiver-background: #eeeeee;
  --alpha-dm-message-mark-as-read: #8a91a8;
  --alpha-dm-input-border: #5b5b5b;
  --alpha-dm-input-background: #000;
  --alpha-dm-input-text-background: #2e2e2e;
  --alpha-dm-input-send-icon: #f8f0ac;

  //=======custom=====//
  &.is-alpha-dark {
    background: #212121;

    //custom Chakra
    @media screen and (max-width: 1024px) {
      --chakra-colors-chakra-body-bg: #212121 !important;
      --chakra-colors-text-primary: #fff;
      --chakra-colors-blue-600: #f8f0ac;
      --alpha-listMenu: #292929;
      --alpha-chakraBox: rgba(255, 255, 255, 0.1);
      --alpha-chakraContent: #fff;
      --alpha-avatar-border: #000;
      --alpha-chakraInput: #fff;
      --alpha-notiChakra: rgba(255, 255, 255, 0.7);
      --chakra-colors-text-blue: #f8f0ac;
      --alpha-formDesc: rgba(255, 255, 255, 0.7);
      --alpha-formBoxBg: rgba(255, 255, 255, 0.1);
      --popper-arrow-bg: rgba(255, 255, 255, 0.1);
      --chakra-colors-blackAlpha-400: rgba(0, 0, 0, 0.8) !important;
    }

    --alpha-bg: #000;
    --alpha-text: #fff;
    --alpha-navBg: #000;
    --alpha-icon: #fff;
    --alpha-createBtnBg: #fff;
    --alpha-navBorder: rgba(255, 255, 255, 0.15);
    --alpha-headerBg: #000;
    --alpha-headerBorder: #212121;
    --alpha-stepItem-border: #000;
    --alpha-stepItem-bg: #000;
    --alpha-btn-bg: #f8f0ac;
    --alpha-btn-text: #000;
    --alpha-hastag: #f8f0ac;
    --alpha-svgPath: #fff;
    --alpha-referAdnEarnBorder: transparent;
    --alpha-referAdnEarnBg: rgba(255, 255, 255, 0.1);
    --alpha-referAdnEarnText: #fff;
    --alpha-tipsWrapperBg: #292929;
    --alpha-tipsDesc: rgba(255, 255, 255, 0.6);
    --alpha-tipsBtn: #f8f0ac;
    --alpha-tipsBtnSvg: #000;
    --alpha-confirmBorder: transparent;
    --alpha-confirmBg: #000;
    --alpha-confirmText: #fff;
    --alpha-backBtnBg: transparent;
    --alpha-subTitle: rgba(255, 255, 255, 0.6);
    --alpha-tradeSell: #f6f6f6;
    --alpha-chakraBtnBg: #f8f0ac;
    --alpha-chakraBtnText: #000;
    --alpha-postLine: rgba(255, 255, 255, 0.05);
    --alpha-postBtnBg: #f8f0ac;
    --alpha-slideThumbBorder: rgba(255, 255, 255, 0.1);
    --alpha-checkBox: #f8f0ac;
    --alpha-checkBoxIcon: #000;
    --alpha-bgFlashing50: rgba(248, 240, 172, 0.05);
    --alpha-bgFlashing0: rgba(248, 240, 172, 0.3);
    --alpha-btnProBg: #f8f0ac;
    --alpha-btnProBorder: #000;
    --alpha-btnProText: #000;
    --alpha-uploadImgIcon: #fff;
    --alpha-uploadImgBg: rgba(255, 255, 255, 0.1);
    --alpha-uploadImgStroke: transparent;
    --alpha-blockWrapperShadow: rgba(0, 0, 0, 0);
    --alpha-dataPreviewBorder: rgba(255, 255, 255, 0.1);
    --alpha-confirmModalBg: #292929;
    --alpha-formNoti: rgba(255, 255, 255, 0.6);
    --alpha-unReportBtnBg: #f8f0ac;
    --alpha-notifyBg: #000;
    --alpha-notifyBgText: #fff;
    --alpha-iconSvg: #fff;
    --alpha-activeFilter: #f8f0ac;
    --alpha-createModalBg: rgba(247, 239, 171, 0.15);
    --alpha-createModalText: #f8f0ac;
    --alpha-bottomBar: rgba(255, 255, 255, 0.15);
    --alpha-updateGifShadow: rgba(255, 255, 255, 0.15);
    --alpha-secureWalletBorder: rgba(255, 255, 255, 0.1);
    --alpha-placeholderInputChat: rgba(255, 255, 255, 0.6);
    --alpha-jackpotBtnBg: #f8f0ac;
    --alpha-jackpotBtnDBg: #f8f0ac;
    --alpha-earnBtn: #fff;
    --alpha-uploadMediaIcon: rgba(255, 255, 255, 0.7);
    --alpha-tablist: rgba(255, 255, 255, 0.6);
    --alpha-growUp: linear-gradient(180deg, #a3ffd3 0%, #4cfea6 100%);
    --alpha-growDown: linear-gradient(180deg, #ffb3b2 0%, #ff7f7e 100%);
    --alpha-itemActive: #000;
    --alpha-receiverBg: rgba(255, 255, 255, 0.1);
    --alpha-receiverText: #fff;
    --alpha-redDotBorder: #000;
    --alpha-redDotBorderDM: #212121;
    --alpha-directionPost: rgba(255, 255, 255, 0.5);
    --alpha-itemKeyBorder: rgba(255, 255, 255, 0.1);
    --alpha-switchHolding: #000;
    --alpha-welcomeLink: #f8f0ac;
    --alpha-welcomeLinkBg: #000;
    --alpha-welcomeLinkBorder: #000;
    --alpha-welcomeLinkShadow: transparent;
    //Header
    --alpha-iconWrapper: rgba(255, 255, 255, 0.1);
    --alpha-topUpBtn: #f8f0ac;
    --alpha-topUpSvg: #000;
    --alpha-profileBtn: rgba(255, 255, 255, 0.1);
    --alpha-profileBtnBorder: transparent;
    --alpha-tabListBg: #212121;
    --alpha-tabListBorder: rgba(255, 255, 255, 0.05);

    //Activities
    --alpha-feedItem: #212121;
    --alpha-feedItemBorder: #212121;
    --alpha-feedItemBorderSpec: rgba(255, 255, 255, 0.05);
    --alpha-feedItemName: #f8f0ac;
    --alpha-feedItemPrice: #fff;
    --alpha-feedItemSvg: #fff;

    //Airdrop
    --alpha-warningBlockBg: rgba(255, 255, 255, 0.05);
    --alpha-warningBlockBorder: rgba(255, 255, 255, 0.1);
    --alpha-pointContentBg: #000;
    --alpha-pointContentBorder: #000;
    --alpha-pointContentShadow: rgba(116, 200, 246, 0.16);
    --alpha-num: rgba(255, 255, 255, 0.7);
    --alpha-pointNumber: #f8f0ac;

    //CreatePost
    --alpha-createPostBtn: rgba(248, 240, 172);
    --alpha-replyName: #f8f0ac;
    --alpha-twId: #fff;

    //Trade
    --alpha-wrapper: #000;
    --alpha-wrapperBorder: #000;
    --alpha-backAction: #fff;
    --alpha-postItemBorder: rgba(255, 255, 255, 0.15);
    --alpha-divider: rgba(255, 255, 255, 0.4);
    --alpha-tradeBg: #212121;
    --alpha-form: #212121;
    --alpha-infoKeyContainerBg: rgba(68, 68, 68, 1);
    --alpha-infoKeyContainerBorder: rgba(255, 255, 255, 0.1);
    --alpha-tcText: #fff;
    --alpha-tradeSubmit: #f8f0ac;
    --alpha-backContainerBg: #212121;
    --alpha-tradeSell: rgba(255, 255, 255, 0.1);

    //Wallet
    --alpha-walletBg: #212020;
    --alpha-walletBox: #000;
    --alpha-walletBorder: rgba(255, 255, 255, 0.1);
    --alpha-walletInfo: #000;
    --alpha-safeGuard: #fca668;
    --alpha-walletBtn: rgba(255, 255, 255, 0.1);
    --alpha-walletBtnText: #fff;
    --alpha-walletBtnIcon: #fff;
    --alpha-walletBlockBg: rgba(255, 255, 255, 0.1);
    --alpha-walletBlockBorder: rgba(255, 255, 255, 0.1);
    --alpha-walletSubmitBtn: #f8f0ac;
    --alpha-menuListBorder: #292929;
    --alpha-menuListInputBg: #000;
    --alpha-menuListInputColor: #fff;
    --alpha-menuListInputBorder: #000;
    --alpha-codeBlockBg: rgba(255, 255, 255, 0.1);
    --alpha-codeBlockBorder: rgba(255, 255, 255, 0.1);
    --alpha-walletEarn: #f8f0ac;
    --alpha-codeBg: #000;

    // DM
    --alpha-dm-background: #2d2d2d;
    --alpha-dm-border: #212020;
    --alpha-dm-primary-text-color: #fff;
    --alpha-dm-secondary-text-color: #000;
    --alpha-dm-unread-color: #f8f0ac;
    --alpha-dm-unread-text-color: #8c6d00;
    --alpha-dm-online-background: #2cc069;
    --alpha-dm-online-border: #fff;

    --alpha-dm-link-color: #3369ff;
    --alpha-dm-message-sender-background: #ffbc8c;
    --alpha-dm-message-receiver-background: #eeeeee;
    --alpha-dm-message-mark-as-read: #8a91a8;
    --alpha-dm-input-border: #5b5b5b;
    --alpha-dm-input-background: #000;
    --alpha-dm-input-text-background: #2e2e2e;
    --alpha-dm-input-send-icon: #f8f0ac;

    // Slider
    --alpha-slider-bg: #f8f0ac;
    --alpha-slider-track-bg: #687684;
    --alpha-slider-thumb-border: #4185ec66;

    // Tab
    --alpha-tabs-border: #ffffff26;
    --alpha-tabs-border-active: #ffffff;

    // Modal
    --pro-font-family: var('$font-alp');
  }
}
