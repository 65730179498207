@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.referralEarning {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #000000;
  background-color: #212020;

  // padding-top: constant(safe-area-inset-top);
  // padding-top: env(safe-area-inset-top);

  .container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #212020;
    transition: all 200ms;
    display: flex;
    flex-direction: column;

    .heading {
      width: 100%;
      flex: 0;
      padding: 20px 0;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      background-color: #212020;

      .heading__text {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;

        box-shadow: 0px 0px 10.37429141998291px -3.890359401702881px #0000000f;
        padding: 4px 8px;
        border-radius: 100px;
        background: #ffffff26;
      }

      .heading__balance {
        font-size: 36px;
        line-height: 43px;
        color: #f8f0ac;
      }

      .heading__close {
        position: absolute;
        top: 20px;
        left: 16px;
        z-index: 10;

        &:hover,
        &:active {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }

    .body {
      flex: 1;
      overflow: hidden;

      .body__inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        background-color: #212020;

        .loading {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 10;
          top: 0;
          left: 0;
          background-color: #ffffff80;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .shareInfo {
        .shareInfo__footer {
          padding: 16px 16px;
        }
        .shareInfo__block {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .shareInfo__block__title {
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            color: rgba($color: #000000, $alpha: 0.7);
            text-align: center;
            user-select: none;
            white-space: nowrap;
          }

          .shareInfo__block__amount {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #000;
            text-align: center;
            user-select: none;
            white-space: nowrap;
          }
        }

        .shareInfo__invited {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .twitterList {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: #212020;
      }

      .claimSection {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        background-color: #000;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .claimSection__message {
          font-weight: 600;
          font-size: 14px;
          line-height: 12px;
          color: #fff;
        }

        .claimSection__highlight {
          color: #f8f0ac;
        }

        .claimSection__btn {
          background: #f8f0ac;
          border-radius: 1000px;
          padding: 8px 40px;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #000;
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 40px;
          min-height: 40px;
        }

        .claimSection__btn__inactive {
          opacity: 0.5;
        }
      }
    }
  }
}

.footer {
  background-color: #000;
  padding: 20px;
  display: flex;
  flex: 0;
  min-height: 90px;

  .footer__col {
    flex: 0 33.333%;
    display: flex;
    flex-direction: column;
    gap: 2px;

    .footer__col__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      opacity: 0.7;
    }

    .footer__col__value {
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      color: #f8f0ac;

      .footerInvited {
        display: flex;
        gap: 4px;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        list-style: 120%;
        text-decoration: underline;
      }

      .footerClaimed {
        display: flex;
        gap: 4px;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        list-style: 120%;
        text-decoration: underline;
      }
    }

    .footerClaimAction {
      width: 100%;
      height: 100%;

      .claimSection__btn {
        width: 100%;
        height: 100%;
        border-radius: 1000px;
        padding: 8px 16px;
        background: #f8f0ac;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        &.claimSection__btn__inactive {
          opacity: 0.5;
        }

        .claimSection__btn__label {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #000;
        }

        .claimSection__btn__value {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000;
        }
      }
    }
  }
}
